import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// icons
import { ReactComponent as ScrollDownIcon } from 'assets/images/scroll_arrow.svg';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Button from 'now-frontend-shared/components/Button';

// modal
import SignupModal from 'components/Modals/SignupModal';

// styles
import styles from './styles';

const TopSection = ({ classes }) => {
  const history = useHistory();
  const { setModal } = useModalSetter();

  const handleOpenSignUpPage = () => {
    setModal(<SignupModal />);
  };

  const handleOpenContactUsForm = () => {
    history.push({ pathname: '/contact-us' });
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.wrapper}
    >
      <Grid container justify="center" className={classes.videoContainer}>
        <video
          preload="auto"
          autoPlay
          muted
          loop
          playsInline
          className={classes.video}
        >
          <source
            src="https://nonopwells.com/manual-assets/NOW-video-loop-compressed.mp4"
            type="video/mp4"
          />
        </video>
      </Grid>

      <Grid
        container
        item
        direction="column"
        className={classes.descriptionContainer}
      >
        <div>
          <div className={classes.noWrap}>A&D SERVICE FOR AFE &</div>
          <div className={classes.noWrap}>WELLBORE INTERESTS</div>
        </div>
        <div className={classes.descriptionText}>
          Sellers can create listings, select bid preferences, control timelines
          and launch structured sales opportunities in minutes. Buyers can view
          all pertinent deals, data and submit bids in one centralized location.
        </div>
        <Grid
          item
          className={classes.middleButtonContainer}
          alignItems="flex-start"
        >
          <Grid item className={classes.button}>
            <Button
              label="REGISTER"
              buttonColor="primaryDark"
              fullWidth
              onClick={handleOpenSignUpPage}
              className={classes.button}
            />
          </Grid>
          <Grid item className={classes.button}>
            <Button
              label="REQUEST A DEMO"
              buttonColor="primaryDark"
              fullWidth
              onClick={handleOpenContactUsForm}
              className={classes.button}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.scrollDownIcon}
      >
        <a href="#overview_section">
          <ScrollDownIcon />
        </a>
      </Grid>
    </Grid>
  );
};

TopSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(TopSection);
