import React, { memo } from 'react';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import ErrorField from '../ErrorField';
import styles from '../styles';

const SsnOrTinInput = ({
  classes, input, meta, renderInput,
}) => {
  const isError = meta.touched && meta.error;

  return (
    <>
      <ReactInputMask
        className={classes.maskStyles}
        mask="100-00-0000"
        formatChars={{
          1: '[0-9]',
          0: '[0-9]',
        }}
        id={input.name}
        maskChar="X"
        type="input"
        {...input}
      >
        {renderInput}
      </ReactInputMask>

      {!renderInput && <ErrorField error={isError ? meta.error : null} />}
    </>
  );
};

SsnOrTinInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  renderInput: PropTypes.func,
};

SsnOrTinInput.defaultProps = {
  meta: {},
  renderInput: undefined,
};

export default compose(withStyles(styles), memo)(SsnOrTinInput);
