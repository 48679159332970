import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import queryString from 'query-string';
import styles from './styles';
import { signSellerAgreement } from 'store/actions/companyActions';
import defaultNonPreFilledAgreementPdf from 'now-shared/assets/docs/seller-agreement.pdf';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';
import DocumentViewLayout, { DocumentLayoutContainer } from 'now-frontend-shared/layouts/DocumentViewLayout';
import SignatureFormLayout from 'now-frontend-shared/layouts/SignatureFormLayout';
import { getAgreementSignatureAttachments } from 'now-frontend-shared/helpers/pdfAttachment';
import { getNonOpWellsSignatureDetails } from 'store/actions/companiesActions';
import IconSaveAlt from '@material-ui/icons/SaveAlt';

import {
  validateSignatureForm,
} from 'now-shared/validation/signature-validation';
import { formValidationHasErrors } from 'now-shared/validation/validation-rules';
import {
  getAgreementName,
  sellerAgreementName,
} from 'now-shared/helpers/company-helpers';
import { downloadFileAsFilename } from 'now-frontend-shared/utils/download-helpers';
import { COMPANY_DASHBOARD } from 'constants/registrationFlow';
import { toDateString, getSignatureDateString } from 'now-shared/helpers/time-helpers';
import TipTap, { ExtensionName } from 'now-frontend-shared/components/TipTap';
import { apiAuthTokenQueryParamName } from 'now-shared/helpers/auth-helpers';
import { getAccessToken } from 'auth/auth-helpers';
import Spinner from 'now-frontend-shared/components/Spinner';
import { getSellerAgreementTemplate } from 'store/actions/authActions';
import {
  agreementPageSettings,
  doesAgreementHaveDocument,
  isCustomAgreement,
  isOldNonPreFilledAgreement,
  oldNonPreFilledSellerAgreementVersion,
} from 'now-shared/helpers/agreement-helpers';
import { getSellerAgreementAttachmentOffsets } from 'now-frontend-shared/helpers/seller-agreement-pdf';
import { getAllStates } from 'store/actions/statesActions';
import Onboarding from 'layouts/Onboarding';

const agreementName = sellerAgreementName;

function SellerCertificateView({
  classes,
  authUserFullName,
  states,
  stateId,
  accountManager,
  streetAddress1,
  streetAddress2,
  businessCity,
  businessZip,
  sellerAgreement,
  sellerAgreementTemplate,
  sellerAgreementTemplateIsLoading,
  isSubmitting,
  signer2Name,
  signer2Title,
  company,
  authUserCompany,
}) {
  const hasSavedAgreement = !!sellerAgreement;

  const savedAgreementIsMissingDocument = !!sellerAgreement && !doesAgreementHaveDocument(sellerAgreement);

  const [signature, setSignature] = useState({
    // TODO: [BUG][INTEGRITY][UX] if anyone besides the original signer is viewing this page, this name and
    // signature below will not be correct.
    name: authUserFullName,
    date: getSignatureDateString(),
    signature: '',
  });

  useEffect(() => {
    setSignature(data => ({
      // TODO: [BUG][INTEGRITY][UX] if anyone besides the original signer is viewing this page, this name and
      // signature below will not be correct.
      ...data,
      date: toDateString(sellerAgreement?.wasSignedOnCreation ? sellerAgreement.createdAt : new Date()),
      // TODO: [BUG] This value should be initialized with the signature value that user did in the registration step,
      // not necessarily the same as the user's current full name.
      signature: sellerAgreement?.wasSignedOnCreation ? authUserFullName : '',
    }));
  }, [sellerAgreement, authUserFullName]);

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => { dispatch(getNonOpWellsSignatureDetails()); }, [dispatch]);

  useEffect(() => {
    dispatch(getAllStates());
  }, [dispatch]);

  const signatureChange = e => setSignature({ ...signature, signature: e.target.value });

  const newSignature = signature.signature;

  const printedName = signature.name;

  const signatureDate = signature.date;

  const errors = validateSignatureForm({ signature: newSignature, printedName });

  const [tipTapContent, setTipTapContent] = useState(undefined);

  const getAddressState = () => states.filter(state => state.id === stateId);
  const addressState = getAddressState();

  const businessAddressLine1 = streetAddress2 ? `${streetAddress1}, ${streetAddress2}` : `${streetAddress1}`;
  const businessAddressLine2 = `${businessCity}, ${addressState[0]?.title} ${businessZip}`;

  const tipTapData = useMemo(() => ({
    [ExtensionName.Company]: {
      company: authUserCompany ?? company,
    },
    [ExtensionName.AccountManager]: {
      accountManager,
    },
    [ExtensionName.BusinessAddressLine1]: {
      businessAddressLine1,
    },
    [ExtensionName.BusinessAddressLine2]: {
      businessAddressLine2,
    },
  }), [
    authUserCompany,
    company,
    accountManager,
    businessAddressLine1,
    businessAddressLine2,
  ]);

  const hasAgreementFileStored = !!sellerAgreement?.document;

  const hasCustomAgreement = !!sellerAgreement && isCustomAgreement(sellerAgreement);

  const hasOldNonPreFilledAgreement = !!sellerAgreement && isOldNonPreFilledAgreement(sellerAgreement);

  const isRenderingWithTipTap = !hasSavedAgreement;

  let documentDownloadUrl;
  let documentDisplayedUrl;
  if (hasAgreementFileStored) {
    documentDownloadUrl = sellerAgreement.document.downloadUrl;
  } else if (hasOldNonPreFilledAgreement) {
    documentDownloadUrl = defaultNonPreFilledAgreementPdf;
  } else if (!savedAgreementIsMissingDocument) {
    documentDisplayedUrl = `${process.env.REACT_APP_API_URL}/seller-agreement-pdf`;
    documentDownloadUrl = queryString.stringifyUrl({
      url: documentDisplayedUrl,
      query: {
        // TODO: [SECURITY][FEATURE] find a way to download the file
        // without having to send the auth token in the URL?
        [apiAuthTokenQueryParamName]: getAccessToken(),
      },
    });
  }

  if (!documentDisplayedUrl) {
    documentDisplayedUrl = documentDownloadUrl;
  }

  useEffect(() => {
    if (isRenderingWithTipTap) {
      dispatch(getSellerAgreementTemplate());
    }
  }, [dispatch, isRenderingWithTipTap]);

  useEffect(() => {
    if (sellerAgreementTemplate) {
      setTipTapContent(sellerAgreementTemplate.document);
    }
  }, [sellerAgreementTemplate]);

  const attachments = (hasAgreementFileStored || isRenderingWithTipTap)
    ? []
    : getAgreementSignatureAttachments({
      pageNumber: getSellerAgreementAttachmentOffsets.pageNumber,
      offsetLeft: getSellerAgreementAttachmentOffsets.offsetLeft,
      offsetTop: getSellerAgreementAttachmentOffsets.offsetTop,
      signer1Name: signature.signature,
      signer1Title: printedName,
      signer2Name,
      signer2Title,
    });
  const tipTapView = isRenderingWithTipTap
    ? (
      <>
        <Box
          display="flex"
          flexDirection="column"
          width={1}
          style={{
            width: agreementPageSettings.width,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width={1}
            style={{
              paddingTop: agreementPageSettings.margin.top,
              paddingBottom: agreementPageSettings.margin.bottom,
              paddingLeft: agreementPageSettings.margin.left,
              paddingRight: agreementPageSettings.margin.right,
              boxSizing: 'border-box',
            }}
          >
            <TipTap
              extensionData={tipTapData}
              content={tipTapContent}
            />
          </Box>
        </Box>
      </>
    )
    : null;

  const handleClick = async () => {
    // TODO [BUG] Make the backend accept a new signature
    if (!hasSavedAgreement) {
      await new Promise((resolve, reject) => dispatch(signSellerAgreement({
        signature: signature.signature,
        docVersion: sellerAgreementTemplate.version,
        resolve,
        reject,
      })));
    }
    history.push(COMPANY_DASHBOARD);
  };

  return (
    <Onboarding
      title="Seller's Agreement"
      subTitle="Please upload the following documents"
      nextDisabled={hasSavedAgreement
        || (
          formValidationHasErrors(errors)
          || !signature.date
          || !signer2Name
          || !signer2Title
          || isSubmitting
          || (isRenderingWithTipTap && sellerAgreementTemplateIsLoading)
          || savedAgreementIsMissingDocument
        )}
      nextClick={handleClick}
      nextText="Submit"
      backButton
    >
      <FormLayout style={{ padding: '0px' }}>
        {(
          isSubmitting
          || (sellerAgreementTemplateIsLoading && isRenderingWithTipTap)
        ) && (
          <Spinner backdrop />
        )}
        {(
          (isRenderingWithTipTap)
            ? (
              <DocumentLayoutContainer>
                {tipTapView}
              </DocumentLayoutContainer>
            )
            : (
              <DocumentViewLayout
                document={documentDownloadUrl}
                attachments={attachments}
              />
            )
        )}
        {documentDownloadUrl && (
          <div className={classes.downloadContainer}>
            <a
              onClick={e => {
                e.preventDefault();
                downloadFileAsFilename({
                  downloadUrl: documentDownloadUrl,
                  filename: getAgreementName({
                    baseName: agreementName,
                    isCustom: hasCustomAgreement,
                    version: (sellerAgreement && isOldNonPreFilledAgreement(sellerAgreement))
                      ? oldNonPreFilledSellerAgreementVersion
                      : undefined,
                    withExtension: true,
                  }),
                });
              }}
              href={documentDisplayedUrl}
              className={classes.download}
              download
            >
              {agreementName}
              <IconSaveAlt />
            </a>
          </div>
        )}
        <SignatureFormLayout
          data-cy="sellerAgreementSignature"
          onSignatureChange={signatureChange}
          newSignature={newSignature}
          printedName={printedName}
          signatureDate={signatureDate}
          errors={errors}
          alreadySigned={sellerAgreement?.wasSignedOnCreation}
          hideFields={hasCustomAgreement || savedAgreementIsMissingDocument}
        />
      </FormLayout>
    </Onboarding>
  );
}

SellerCertificateView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  accountManager: PropTypes.string,
  streetAddress1: PropTypes.string,
  streetAddress2: PropTypes.string,
  businessCity: PropTypes.string,
  businessZip: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  states: PropTypes.objectOf(PropTypes.any).isRequired,
  stateId: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  authUserCompany: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  sellerAgreement: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  sellerAgreementTemplate: PropTypes.object,
  sellerAgreementTemplateIsLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  authUserFullName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  company: PropTypes.object.isRequired,
};

SellerCertificateView.defaultProps = {
  authUserCompany: undefined,
  accountManager: undefined,
  streetAddress1: undefined,
  streetAddress2: undefined,
  businessCity: undefined,
  businessZip: undefined,
  stateId: undefined,
  sellerAgreement: undefined,
  sellerAgreementTemplate: undefined,
};

export default compose(
  connect(({
    auth,
    companies,
    company,
    states,
  }) => ({
    company,
    fullLegalCompanyName: auth.user.company?.fullLegalCompanyName,
    authUserFullName: getUserFullName(auth.user),
    // TODO: [VALIDATION] accoundManager Node should populate with Company Account Manager rather than current User
    accountManager: getUserFullName(auth.user),
    streetAddress1: auth.user.company.businessStreetAddress,
    streetAddress2: auth.user.company.businessStreetAddress2,
    businessCity: auth.user.company.city,
    businessZip: auth.user.company.zipCode,
    states: states.states,
    stateId: auth.user.company.stateId,
    sellerAgreement: auth.user.company?.sellerAgreement,
    sellerAgreementTemplate: auth.sellerAgreementTemplate,
    sellerAgreementTemplateIsLoading: auth.sellerAgreementTemplateIsLoading,
    isSubmitting: auth.isSubmittingAgreement,
    signer2Name: companies.nonOpWellsSignatureDetails?.name || '',
    signer2Title: companies.nonOpWellsSignatureDetails?.title || '',
    authUserCompany: auth.user.company,
  })),
  withStyles(styles),
)(SellerCertificateView);
