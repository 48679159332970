/* eslint-disable quote-props */
import React, {
  memo,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import { DateTime, Interval } from 'luxon';
import notificationIcon from './components/notification.svg';
import * as XLSX from 'xlsx';
import { getUserPermissions } from 'now-frontend-shared/helpers/getUserPermissions';

import { isUsingEsriMaps } from 'now-frontend-shared/features/feature-flags';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import GoogleMap from 'now-frontend-shared/components/GoogleMap';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import Countdown from 'now-frontend-shared/components/Countdown';
import StatusBadge from 'now-frontend-shared/components/StatusBadge';
import Spinner from 'now-frontend-shared/components/Spinner';
import TotalListingCost from 'components/TotalListingCost';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DocumentsMenu from './components/DocumentsMenu';
import DocumentsTable from './components/DocumentsTable';
import EsriMap from 'now-frontend-shared/components/EsriMap';
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import Watermark from 'now-frontend-shared/components/Watermark';

import AmountInput from './components/AmountInput';
import PlaceBidButton from './components/PlaceBidButton';
import StyledLink from 'pages/Profile/components/StyledLink';
import SubmitConfirmationModal from 'now-frontend-shared/components/modals/SubmitConfirmationModal';
import { getCurrentProperty, makeBet } from 'store/actions/propertyViewActions';
import { FormulaTooltip } from 'now-frontend-shared/components/Tooltips';

// back arrow
import { ReactComponent as BackArrow } from 'now-frontend-shared/assets/icons/blue_back_arrow.svg';

// helpers
import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import {
  listingIsActive,
  listingHasBeenActive,
  listingNotYetClosed,
  listingHasBeenClosed,
} from 'now-shared/validation/listing-validation';

import {
  formatNumberToCurrency,
  maskedAmountToNumber,
} from 'now-shared/helpers/currency-helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import {
  nonOpWellsDateTime,
  toAuctionEventTime,
} from 'now-shared/helpers/time-helpers';
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import {
  isListingTransactionCanceled,
  isListingTransactionComplete,
} from 'now-shared/helpers/escrow-transaction-helpers';
import { ClosingMethod } from 'now-shared/enums/closing-method';
import {
  listingDocumentAdditionalTypeMap,
  listingDocumentNewTypeMap,
} from 'now-shared/enums/listing-document-type';
import { computeWellsGrossAfe } from 'now-shared/helpers/listing-helpers';
import { eightsCalculations } from 'layouts/BidInfoLayout/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid, makeStyles, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import LaunchIcon from '@material-ui/icons/Launch';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import StepConnector from '@material-ui/core/StepConnector';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { toast } from 'react-toastify';

// styles
import styles from './styles';

// store
import {
  addListingDocumentDownload,
  addListingPageView,
} from 'store/actions/listingAnalyticsActions';
import {
  computeCommission,
  computeTotalListingCost,
  grantConsideration,
  computeImpliedPrice,
  computeAcquiredRetainedInterest,
  roundUpToTenDecimalPoints,
} from 'now-shared/helpers/bid-helpers';
import { TransactionTypes } from 'now-shared/enums/transaction-types';
import { formValues, reduxForm, change } from 'redux-form';

export function getMyBidStatusText(myBid) {
  if (myBid?.status.title === 'winner') {
    return 'You won!';
  }
  if (myBid) {
    return 'You have already bid on this listing';
  }
  return 'You haven’t bid on this listing';
}

function getBidUsername({ user }) {
  return getUserFullName(user);
}

function getBidCompanyName({ user: { company } }) {
  return getCompanyName(company);
}

const TRANSACTION_CREATED = 'transactionCreated';
const PAYMENT_SENT = 'paymentSent';
const PAYMENT_SECURED = 'paymentSecured';
const DOCUMENTS_SENT = 'documentsSent';
const DOCUMENTS_RECEIVED = 'documentsReceived';
const DOCUMENTS_ACCEPTED = 'documentsAccepted';
const FUNDS_DISBURSED = 'fundsDisbursed';
const TRANSACTION_CLOSED = 'transactionClosed';

const steps = [
  {
    id: TRANSACTION_CREATED,
    label: 'Transaction created',
    icon: <LaunchIcon />,
    closingMethods: Object.values(ClosingMethod),
  },
  {
    id: TRANSACTION_CLOSED,
    label: 'Transaction closed',
    icon: <CheckCircleIcon />,
    closingMethods: Object.values(ClosingMethod),
  },
];

const stepIds = steps.map(step => step.id);
const stepIdStepMap = new Map(steps.map(({ id, ...step }) => [id, step]));

function getStepIndex(stepId, visibleStepIds = stepIds) {
  return visibleStepIds.indexOf(stepId);
}

function stepIdFromNumber(stepNumber, visibleStepIds = stepIds) {
  return visibleStepIds[stepNumber - 1];
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const {
    active, completed, icon: stepNumberString, visibleStepIds,
  } = props;

  const stepId = useMemo(
    () => stepIdFromNumber(+stepNumberString, visibleStepIds),
    [stepNumberString, visibleStepIds],
  );

  return (
    <div
      className={cx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {stepIdStepMap.get(stepId).icon || stepNumberString}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node.isRequired,
  visibleStepIds: PropTypes.arrayOf(PropTypes.string.isRequired),
};

ColorlibStepIcon.defaultProps = {
  active: undefined,
  completed: undefined,
  visibleStepIds: stepIds,
};
// TODO: [CLEANUP] [NOW-1795] Remove references to Escrow.com transaction closing process
// Refs to Escrow.com transaction closing process in Stepper
const PropertyView = ({
  classes,
  match,
  property,
  user,
  isAdminOrComplianceUser,
  companyIsApprovedAndActive,
  hasBankInformation,
  hasBuyerAgreement,
  handleSubmit,
  userFullName,
  companyName,
}) => {
  const dispatch = useDispatch();
  const { setModal } = useModalSetter();
  const [payTimeRemaining, setPayTimeRemaining] = useState('');
  const [canBidOnProperty, setCanBidOnProperty] = useState(false);
  const [wellAllocationBids, setWellAllocationBids] = useState({});
  const [propertyDataForExport, setPropertyDataForExport] = useState({});
  const [wellsDataForExport, setWellsDataForExport] = useState({});
  const projectName
    = property && property.projectName
      ? property.projectName.replace(/\s/g, '-')
      : 'listingInfo';
  const xcelFileName = `${projectName}-info.xlsx`;

  const listingId = match.params.id;
  const watermarkText = `${companyName} / ${userFullName}`;
  const userPermissions = getUserPermissions();

  const findAll = (arr, callback) => arr.filter((element, index, array) => callback(element, index, array));

  useEffect(() => {
    dispatch(getCurrentProperty(listingId));
  }, [dispatch, listingId]);

  const wellsAugmented = useMemo(
    () => property?.wells.map(well => ({ ...well, property })),
    [property],
  );

  const propertyWells
    = property?.wells?.sort((a, b) => {
      if (+a.rank < +b.rank) {
        return -1;
      }
      if (+a.rank > +b.rank) {
        return 1;
      }
      return 0;
    }) || [];

  const getCoordinates = useCallback(() => {
    if (property?.wells) {
      const polylinesCoordinates = [];
      const markersCoordinates = [];

      propertyWells.forEach(
        ({
          surfaceLatitude,
          surfaceLongitude,
          bottomLatitude,
          bottomLongitude,
        }) => {
          if (
            surfaceLatitude
            && surfaceLongitude
            && bottomLatitude
            && bottomLongitude
          ) {
            polylinesCoordinates.push([
              { lat: +surfaceLatitude, lng: +surfaceLongitude },
              { lat: +bottomLatitude, lng: +bottomLongitude },
            ]);

            markersCoordinates.push({
              lat: +surfaceLatitude,
              lng: +surfaceLongitude,
            });
          }
        },
      );

      return { polylinesCoordinates, markersCoordinates };
    }
  }, [property]);

  useEffect(() => {
    if (property) {
      setPropertyDataForExport({
        'Listing Name': property?.projectName,
        Operator: property?.operatorName,
        Seller: property.isAnonymous
          ? 'Anonymous'
          : getCompanyName(property.user.company),
        State: property?.state.title,
        Basin: property?.basin.title,
        County: property?.county.title,
      });
    }
  }, [property]);

  useEffect(() => {
    if (property) {
      const wellsData = property.wells
        ? property.wells.map(well => {
          const eightsRevenueInterest = eightsCalculations(well);

          return {
            'Well Name': well.wellName,
            'Well API Number': well.wellAPINumber,
            'Landing Zone': well.landingZone.title,
            'Total Vertical Depth': well.wellTotalVerticalDepth,
            'Surface Latitude': well.surfaceLatitude,
            'Surface Longitude': well.surfaceLongitude,
            'Bottom Hole Latitude': well.bottomLatitude,
            'Bottom Hole Longitude': well.bottomLongitude,
            'Minimum Bid': well.minimumBid,
            'Minimum Bid Carry': well.minimumBidCarry,
            'Well Net AFE': well.wellNetAFE,
            'Well Gross AFE': well.wellGrossAfe,
            'Net Working Interest': well.workingInterestPercentage,
            'Net Revenue Interest': well.netRevenueInterestNumber,
            '8/8ths Revenue Interest': eightsRevenueInterest,
          };
        })
        : [];

      setWellsDataForExport(wellsData);
    }
  }, [property]);

  const prevBidTypeSelectionRef = useRef();
  const hidePlaceBidButton = false;
  const isOwner = property?.user.id === user.id;
  const isOwnerCompany = property?.user?.company?.id === user.companyId;
  const isBuyer = !isOwnerCompany && !isAdminOrComplianceUser;
  const existingBid
    = isOwnerCompany || isAdminOrComplianceUser ? undefined : property?.bids[0];
  const isEitherAndHasTwoBids
    = property?.transactionType === TransactionTypes.Either
    && property?.bids.length < 2
      ? isOwnerCompany || isAdminOrComplianceUser
        ? undefined
        : false
      : existingBid;

  const isPropertyArchivedAndBuyer = () => {
    if (!property?.archivedAt) return true;
    if (isOwner) return true;
    if (isAdminOrComplianceUser) return true;
    return false;
  };

  const winningBid
    = property?.bids[0]?.status.title === 'winner'
      ? property?.bids[0]
      : undefined;
  const totalWellGrossAfe = computeWellsGrossAfe(property?.wells);
  const netAfe
    = typeof property?.netAfe === 'string' ? +property.netAfe : property?.netAfe;
  const coordinates = useMemo(() => getCoordinates(), [getCoordinates]);
  const listingStatusTitle = property?.status.title;

  useEffect(() => {
    if (isBuyer && listingStatusTitle === 'active') {
      dispatch(addListingPageView({ listingId }));
    }
  }, [dispatch, listingId, listingStatusTitle, isBuyer]);

  const handleDocumentDownload = ({ documentId }) => {
    if (isBuyer && listingStatusTitle === 'active') {
      dispatch(addListingDocumentDownload({ listingId, documentId }));
    }
  };

  const handleExportPropertyInformation = ({ data, wells }) => {
    const wb = XLSX.utils.book_new();

    // Create sheet with property General Information
    const ws = XLSX.utils.json_to_sheet([data]);

    // Append a blank row to separate the General Information from the wells data
    XLSX.utils.sheet_add_json(ws, [{}], { origin: -1 });

    // Add wells data with single headers row
    const wellsWs = XLSX.utils.json_to_sheet(wells);
    XLSX.utils.sheet_add_json(ws, XLSX.utils.sheet_to_json(wellsWs), {
      origin: -1,
      header: Object.keys(wells[0]),
    });

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, xcelFileName);
  };

  const netAfeFormatted = useMemo(
    () => formatNumberToCurrency(netAfe),
    [netAfe],
  );
  const totalWellGrossAfeFormatted = useMemo(
    () => formatNumberToCurrency(totalWellGrossAfe),
    [totalWellGrossAfe],
  );
  const isCarryTransaction
    = property?.transactionType === TransactionTypes.Carry;
  const isEitherTransaction
    = property?.transactionType === TransactionTypes.Either;

  const [bidTypeSelection, setBidTypeSelection] = React.useState(
    isCarryTransaction ? TransactionTypes.Carry : TransactionTypes.Cash,
  );

  useEffect(() => {
    if (existingBid) {
      setBidTypeSelection(
        property?.bids[0].amount
          ? TransactionTypes.Cash
          : TransactionTypes.Carry,
      );
    } else if (isCarryTransaction) {
      setBidTypeSelection(TransactionTypes.Carry);
    } else {
      setBidTypeSelection(TransactionTypes.Cash);
    }
  }, [existingBid, isCarryTransaction, property]);

  const transaction = useMemo(() => {
    let result;
    if (property?.transaction) {
      result = {
        ...property.transaction,
        createdAt: property.escrow?.createdDate || property.soldAt,
        isComplete: isListingTransactionComplete(property),
        isCanceled: isListingTransactionCanceled(property),
      };
    }
    return result;
  }, [property]);

  const visibleStepIds = useMemo(() => steps.map(step => step.id), []);

  let activeStepId;
  if (transaction) {
    if (transaction.isComplete) {
      activeStepId = TRANSACTION_CLOSED;
    } else if (transaction.escrow?.payment.isDisbursed) {
      activeStepId = FUNDS_DISBURSED;
    } else if (transaction.escrow?.item.status.accepted) {
      activeStepId = DOCUMENTS_ACCEPTED;
    } else if (transaction.escrow?.item.status.received) {
      activeStepId = DOCUMENTS_RECEIVED;
    } else if (transaction.escrow?.item.status.shipped) {
      activeStepId = DOCUMENTS_SENT;
    } else if (transaction.escrow?.payment.isSecured) {
      activeStepId = PAYMENT_SECURED;
    } else if (transaction.escrow?.payment.isInitiated) {
      activeStepId = PAYMENT_SENT;
    } else if (!transaction.escrow) {
      activeStepId = TRANSACTION_CREATED;
    }
  }

  const activeStepIndex = getStepIndex(activeStepId, visibleStepIds);

  const lastTimeFiredRef = useRef(0);
  const history = useHistory();

  useEffect(() => {
    let result;
    if (
      transaction?.escrow
      && !transaction.isComplete
      && !transaction.isCanceled
    ) {
      const debounceThresholdMillis = 1000;
      const handleVisibilityChange = () => {
        const now = Date.now();
        if (
          !document.hidden
          && now > lastTimeFiredRef.current + debounceThresholdMillis
        ) {
          lastTimeFiredRef.current = now;
          dispatch(getCurrentProperty(listingId));
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('focus', handleVisibilityChange);
      result = () => {
        window.removeEventListener('focus', handleVisibilityChange);
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
      };
    }
    return result;
  }, [dispatch, transaction, listingId, lastTimeFiredRef]);

  useEffect(() => {
    let result;
    if (
      transaction
      && !isOwnerCompany
      && !isAdminOrComplianceUser
      && !transaction.isComplete
      && !transaction.isCanceled
      && (!transaction.escrow?.payment.isInitiated || !transaction.escrow)
    ) {
      const updateTimeRemaining = () => {
        const dueDate = DateTime.fromISO(transaction.payment.dueDate);
        const nowDate = DateTime.local();
        let remaining = '';
        if (dueDate > nowDate) {
          remaining = Interval.fromDateTimes(nowDate, dueDate)
            .toDuration()
            .toFormat("d'D' hh'H' mm'M'");
        } else {
          remaining = 'no';
        }
        setPayTimeRemaining(remaining);
      };

      updateTimeRemaining();
      const interval = setInterval(updateTimeRemaining, 1000);
      result = () => clearInterval(interval);
    } else {
      setPayTimeRemaining('');
    }
    return result;
  }, [transaction, isOwnerCompany, isAdminOrComplianceUser]);

  const PropertyHeader = ({ updateBidOnProperty }) => {
    if (isOwnerCompany || isAdminOrComplianceUser) {
      if (listingNotYetClosed(property)) {
        return (
          <>
            {listingIsActive(property)
              && !isAdminOrComplianceUser
              && (user.isAuthorizedSigner || user.isViewOnlyListingEditor) && (
                <LinkButton
                  buttonColor="clear"
                  label="Close Auction"
                  path="/contact-us"
                  data-cy="closeAuctionButton"
                />
            )}
            {isOwner
              && (user.isAuthorizedSigner || user.isViewOnlyListingEditor) && (
                <LinkButton
                  dataCy="editListingButton"
                  path={`/listings/${property.id}/edit`}
                  buttonColor="clear"
                  label="Edit"
                />
            )}
          </>
        );
      }
    } else if (user.isAuthorizedSigner) {
      if (existingBid) {
        updateBidOnProperty(true);
      } else if (!listingIsActive(property)) {
        updateBidOnProperty(false);
      } else {
        updateBidOnProperty(true);
      }
      return null;
    }
    return null;
  };

  const totalBidAmount = useMemo(() => {
    if (
      Object.values(wellAllocationBids).length !== property?.wells.length
      || Object.values(wellAllocationBids).some(v => v === 0)
    ) {
      return undefined;
    }
    return Object.values(wellAllocationBids).reduce(
      (acc, val) => Number(acc) + Number(val),
      0,
    );
  }, [wellAllocationBids, property?.wells]);

  const totalImpliedPurchasePrice = useMemo(() => {
    if (
      !existingBid
      && (Object.values(wellAllocationBids).length !== property?.wells.length
        || Object.values(wellAllocationBids).some(v => v === 0))
    ) {
      return undefined;
    }
    let total;

    if (isEitherTransaction) {
      const existingBidForEither
        = isOwnerCompany || isAdminOrComplianceUser ? undefined : property?.bids;
      const existingCashBid = existingBidForEither?.find(item => item.amount);
      const existingCarryBid = existingBidForEither?.find(
        item => item.carryPercentage,
      );

      if (
        existingBidForEither.length
        && bidTypeSelection === TransactionTypes.Cash
        && existingCashBid
        && (Object.values(wellAllocationBids).length !== property?.wells.length
          || Object.values(wellAllocationBids).some(v => v === 0))
      ) {
        return undefined;
      }

      if (existingBidForEither.length && existingCarryBid) {
        total = propertyWells.reduce((acc, item) => {
          const existingBidPerWell = existingCarryBid
            ? property?.bidPerWells?.find(
              well => well.wellId === item.id && well.carryPercentage,
            )
            : undefined;

          const acquiredPercentage = computeAcquiredRetainedInterest({
            bidCarryPercentage: existingBidPerWell?.carryPercentage,
            sellerOriginalOwnershipPercentage: item.workingInterestPercentage,
          });

          return (
            Number(
              computeImpliedPrice(
                item.wellGrossAfe,
                acquiredPercentage.sellerRetainedPercentage,
              ).toFixed(0),
            ) + acc
          );
        }, 0);
      } else {
        if (
          !(
            Object.values(wellAllocationBids).length
              === property?.wells.length
            && Object.values(wellAllocationBids).every(v => v !== 0)
          )
        ) {
          return undefined;
        }
        total = propertyWells.reduce((acc, item) => {
          const acquiredPercentage = computeAcquiredRetainedInterest({
            bidCarryPercentage: wellAllocationBids[item.id],
            sellerOriginalOwnershipPercentage: item.workingInterestPercentage,
          });

          return (
            Number(
              computeImpliedPrice(
                item.wellGrossAfe,
                acquiredPercentage.sellerRetainedPercentage,
              ).toFixed(0),
            ) + acc
          );
        }, 0);
      }
    } else {
      total = propertyWells.reduce((acc, item) => {
        const existingBidPerWell = existingBid
          ? property?.bidPerWells?.find(well => well.wellId === item.id)
          : undefined;

        const acquiredPercentage = computeAcquiredRetainedInterest({
          bidCarryPercentage: existingBid
            ? existingBidPerWell?.carryPercentage
            : wellAllocationBids[item.id],
          sellerOriginalOwnershipPercentage: item.workingInterestPercentage,
        });

        return (
          Number(
            computeImpliedPrice(
              item.wellGrossAfe,
              acquiredPercentage.sellerRetainedPercentage,
            ).toFixed(0),
          ) + acc
        );
      }, 0);
    }
    return total;
  }, [
    property?.bids,
    property?.bidPerWells,
    existingBid,
    wellAllocationBids,
    property?.wells,
    bidTypeSelection,
    existingBid,
    propertyWells,
  ]);

  const closingFeesForAllWells = useMemo(() => {
    if (
      Object.values(wellAllocationBids).length !== property?.wells.length
      || Object.values(wellAllocationBids).some(v => v === 0)
    ) {
      return undefined;
    }
    const fees = computeCommission({
      bidAmount:
        bidTypeSelection === TransactionTypes.Carry
          ? maskedAmountToNumber(totalImpliedPurchasePrice)
          : maskedAmountToNumber(totalBidAmount),
      netAfe: +property?.netAfe,
    });
    return fees;
  }, [
    property?.netAfe,
    wellAllocationBids,
    property?.wells,
    bidTypeSelection,
    totalBidAmount,
    totalImpliedPurchasePrice,
  ]);

  const totalListingCost = useMemo(() => {
    if (
      Object.values(wellAllocationBids).length !== property?.wells.length
      || Object.values(wellAllocationBids).some(v => v === 0)
    ) {
      return undefined;
    }
    if (bidTypeSelection === TransactionTypes.Carry) {
      return closingFeesForAllWells;
    }
    return computeTotalListingCost({
      bidAmount: totalBidAmount,
      closingFees: closingFeesForAllWells,
    });
  }, [
    wellAllocationBids,
    closingFeesForAllWells,
    totalBidAmount,
    property?.wells,
    bidTypeSelection,
  ]);

  const handleBidTypeSelectionChange = type => {
    setBidTypeSelection(type);
    dispatch(change('makeBet', 'carryPercentage', null));
    dispatch(change('makeBet', 'amount', null));
    setWellAllocationBids({});
  };

  function convertToSevenDecimalNumber(str) {
    if (!str) {
      return 0;
    }
    if (typeof str === 'string' && str.endsWith('.')) {
      return str;
    }
    let num = parseFloat(str);
    if (num % 1 !== 0) {
      const decimalString = num.toString().split('.')[1] || '';
      if (decimalString.length > 7) {
        const truncatedDecimalString = decimalString.slice(0, 7);
        num = parseFloat(num.toFixed(truncatedDecimalString.length));
      }
    }
    return num;
  }

  const handleCarryAmountChange = well => e => {
    const value = e?.target?.value?.replace(/[^0-9.-]+/g, '');
    setWellAllocationBids({
      ...wellAllocationBids,
      [well.id]: convertToSevenDecimalNumber(value) || 0,
    });
  };

  const closingCostLegend = useMemo(() => {
    if (existingBid) {
      const bid = property?.bids[0];
      if (bidTypeSelection === TransactionTypes.Carry) {
        return bid.carryPercentage ? bid.commission : undefined;
      }
      return bid.amount ? bid.commission : undefined;
    }
    return closingFeesForAllWells;
  }, [existingBid, property?.bids, closingFeesForAllWells, bidTypeSelection]);

  const closingCostLegendForEither = useMemo(() => {
    const bid = property?.bids;
    const carryBid = bid?.find(item => item.carryPercentage);
    const cashBid = bid?.find(item => item.amount);

    if (bid && bidTypeSelection === TransactionTypes.Carry && carryBid) {
      return carryBid ? carryBid.commission : undefined;
    }
    if (bid && bidTypeSelection === TransactionTypes.Cash && cashBid) {
      return cashBid ? cashBid.commission : undefined;
    }
    return closingFeesForAllWells;
  }, [property?.bids, closingFeesForAllWells, bidTypeSelection]);

  const currentBid = useMemo(() => {
    if (existingBid) {
      const bid = property?.bids[0];
      if (bidTypeSelection === TransactionTypes.Carry) {
        return bid.carryPercentage ? totalImpliedPurchasePrice : undefined;
      }
      return bid.amount || undefined;
    }

    if (bidTypeSelection === TransactionTypes.Carry) {
      return totalImpliedPurchasePrice;
    }
    return totalBidAmount;
  }, [
    bidTypeSelection,
    existingBid,
    property?.bids,
    totalBidAmount,
    totalImpliedPurchasePrice,
  ]);

  const currentBidForEither = useMemo(() => {
    const bid = property?.bids;
    const carryBid = bid?.find(item => item.carryPercentage);
    const cashBid = bid?.find(item => item.amount);

    if (bidTypeSelection === TransactionTypes.Carry) {
      if (bid?.length && carryBid && carryBid.carryPercentage) {
        return carryBid.carryPercentage ? totalImpliedPurchasePrice : undefined;
      }
      return totalImpliedPurchasePrice;
    }
    if (bid?.length && cashBid && cashBid.amount) {
      return cashBid.amount || undefined;
    }
    return totalBidAmount;
  }, [
    bidTypeSelection,
    property?.bids,
    totalBidAmount,
    totalImpliedPurchasePrice,
  ]);

  const totalCostLegend = useMemo(() => {
    if (existingBid) {
      const bid = property?.bids[0];
      if (bidTypeSelection === TransactionTypes.Carry) {
        return bid.carryPercentage ? bid.totalCost : undefined;
      }
      return bid.amount ? bid.totalCost : undefined;
    }
    return totalListingCost;
  }, [existingBid, property?.bids, totalListingCost, bidTypeSelection]);

  const totalCostLegendForEither = useMemo(() => {
    const bid = property?.bids;
    const carryBid = bid?.find(item => item.carryPercentage);
    const cashBid = bid?.find(item => item.amount);

    if (
      bid?.length
      && bidTypeSelection === TransactionTypes.Carry
      && carryBid?.carryPercentage
    ) {
      return carryBid.carryPercentage ? carryBid.totalCost : undefined;
    }
    if (
      bid?.length
      && bidTypeSelection === TransactionTypes.Cash
      && cashBid?.amount
    ) {
      return cashBid.amount ? cashBid.totalCost : undefined;
    }
    return totalListingCost;
  }, [property?.bids, totalListingCost, bidTypeSelection]);

  useEffect(() => {
    prevBidTypeSelectionRef.current = bidTypeSelection;
  }, [bidTypeSelection]);

  useEffect(() => {
    if (wellAllocationBids) {
      const result = Object.values(wellAllocationBids).reduce(
        (acc, val) => acc + val,
        0,
      );
      if (bidTypeSelection === TransactionTypes.Carry) {
        dispatch(change('makeBet', 'carryPercentage', result));
        dispatch(change('makeBet', 'amount', null));
      } else {
        dispatch(change('makeBet', 'amount', result));
        dispatch(change('makeBet', 'carryPercentage', null));
      }
    }
  }, [wellAllocationBids, bidTypeSelection, dispatch]);

  const inputDisabled = useMemo(
    () => !companyIsApprovedAndActive
      || !hasBankInformation
      || !hasBuyerAgreement
      || isEitherAndHasTwoBids
      || isOwner
      || !listingIsActive,
    [
      isEitherAndHasTwoBids,
      companyIsApprovedAndActive,
      hasBankInformation,
      hasBuyerAgreement,
      isOwner,
    ],
  );

  // eslint-disable-next-line no-shadow
  const onHandleSubmit = () => {
    const submitBid = () => {
      const createWellAllocationBids = Object.entries(wellAllocationBids).map(
        ([wellId, value]) => ({
          id: wellId,
          amount: bidTypeSelection === TransactionTypes.Carry ? null : value,
          carryPercentage:
            bidTypeSelection === TransactionTypes.Carry ? value : null,
        }),
      );

      if (
        createWellAllocationBids.length === 0
        || closingFeesForAllWells === undefined
      ) {
        toast.error('Bid value not entered', { position: 'top-center' });
      } else {
        dispatch(
          makeBet({
            grantConsideration,
            commission: closingFeesForAllWells,
            totalCost: totalListingCost,
            id: listingId,
            bidTypeSelection,
            wellAllocationBids: createWellAllocationBids,
          }),
        );
      }
    };

    setModal(
      <SubmitConfirmationModal
        heading="As a reminder, all bids are binding. Are you sure you want to submit this bid?"
        confirmLabel="Submit"
        handleSubmit={submitBid}
      />,
    );
  };

  const backClickHandler = () => {
    history.goBack();
  };

  if (!property) return <Spinner />;

  return (
    <>
      <Header />
      <Grid container className={classes.wrapper}>
        <Grid container justify="center" className={classes.container}>
          <Grid container justify="center" className={classes.topSection}>
            <Grid container className={classes.topSectionContent}>
              <Grid item className={classes.arrow}>
                <BackArrow
                  onClick={backClickHandler}
                  className={classes.icon}
                />
              </Grid>

              <Grid container item xs>
                <Grid
                  container
                  alignItems="center"
                  className={classes.topInfoContainer}
                >
                  <Grid item className={classes.heading}>
                    <span>
                      {`${property.projectName}, `}
                      <span className={classes.wellsCount}>
                        {`${propertyWells.length} ${
                          propertyWells.length > 1 ? 'wells' : 'well'
                        }`}
                      </span>
                    </span>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  className={classes.topSectionContainer}
                >
                  <div className={classes.buttonGroup} style={{ gap: '23px' }}>
                    <Grid className={classes.topSectionHeading}>
                      <span className={classes.topHeading}>Seller:</span>
                      <span className={classes.topSectionDescription}>
                        {property.isAnonymous
                          ? 'Anonymous'
                          : property.manualSellerCompanyName
                            || getCompanyName(property.user.company)
                            || '<missing company>'}
                      </span>
                    </Grid>

                    <Grid className={classes.topSectionHeading}>
                      <span className={classes.topHeading}>Operator:</span>
                      <span className={classes.topSectionDescription}>
                        {property.operatorName}
                      </span>
                    </Grid>

                    {isOwnerCompany || isAdminOrComplianceUser ? (
                      <>
                        <Grid
                          className={cx(
                            classes.topSectionHeading,
                            classes.separation,
                          )}
                        >
                          <span className={classes.topHeading}>
                            Bid status:
                          </span>
                          <span className={classes.sellerBidStatus}>
                            {isOwnerCompany && 'This is your property'}
                            {isAdminOrComplianceUser && 'Admins cannot bid'}
                          </span>
                        </Grid>
                        {winningBid && (
                          <Grid
                            container
                            direction="column"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                            xl={2}
                            className={cx(
                              classes.topSectionHeading,
                              classes.separation,
                            )}
                          >
                            <span className={classes.topHeading}>Winner:</span>
                            <span className={classes.topSectionDescription}>
                              {`${getBidUsername(
                                winningBid,
                              )} (${getBidCompanyName(winningBid)})`}
                            </span>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Grid
                        className={cx(
                          classes.topSectionHeading,
                          classes.separation,
                        )}
                      >
                        <span className={classes.topHeading}>Bid status:</span>
                        <span className={classes.topSectionDescription}>
                          {getMyBidStatusText(existingBid)}
                        </span>
                      </Grid>
                    )}

                    <Grid className={classes.topSectionHeading}>
                      <span className={classes.topHeading}>Location:</span>
                      <span className={classes.topSectionDescription}>
                        {property?.state?.title}
                        {', '}
                        {property?.basin?.title}
                        {', '}
                        {property?.county?.title}
                      </span>
                    </Grid>

                    <Grid item className={classes.listingStatus}>
                      <span className={classes.listingStatusHeading}>
                        Status:
                      </span>
                      <StatusBadge
                        status={
                          existingBid?.status.title === 'winner'
                            ? 'winner'
                            : property.status.title
                        }
                        data-cy="listingStatusBadge"
                      />
                      {property.archivedAt && (
                        <StatusBadge
                          status="archived"
                          data-cy="listingStatusBadge"
                        />
                      )}
                    </Grid>
                  </div>
                  <div className={classes.buttonGroup}>
                    <PropertyHeader updateBidOnProperty={setCanBidOnProperty} />
                    {payTimeRemaining
                      && (payTimeRemaining === 'no' ? (
                        (!!transaction?.escrow && (
                          <span style={{ color: 'red' }}>Payment overdue!</span>
                        ))
                        || 'Payment past due'
                      ) : (
                        <>
                          {payTimeRemaining}
                          {' '}
                          left
                        </>
                      ))}

                    {(isOwnerCompany || isAdminOrComplianceUser)
                      && listingHasBeenActive(property) && (
                        <>
                          <LinkButton
                            path={`/listings/${property.id}/bids`}
                            buttonColor="clear"
                            label={`Bids (${property.bidCount})`}
                            data-cy="bidsButton"
                          />
                          <LinkButton
                            path={`/listings/${property.id}/analytics`}
                            buttonColor="clear"
                            label="Analytics"
                          />
                        </>
                    )}
                    {listingNotYetClosed(property) && (
                      <>
                        <DocumentsMenu
                          documents={property.documents}
                          listingId={property.id}
                          onDownload={handleDocumentDownload}
                          user={user}
                          property={property}
                        />
                        <Button
                          className={classes.downloadButton}
                          onClick={() => handleExportPropertyInformation({
                            data: propertyDataForExport,
                            wells: wellsDataForExport,
                          })}
                        >
                          Download Listing
                        </Button>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.bottomSectionContent}>
            <div>
              {transaction && (
                <>
                  <Watermark text={watermarkText} rotate="-20">
                    <Grid container className={classes.detailsContainer}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <span className={classes.contentHeading}>
                          Transaction Progress
                        </span>
                      </Grid>

                      <Grid
                        container
                        className={classes.bottomContentContainer}
                      >
                        {/* TODO: [UX][MOBILE] make the stepper look good on mobile */}
                        <Stepper
                          alternativeLabel
                          activeStep={activeStepIndex}
                          connector={<ColorlibConnector />}
                          classes={{
                            horizontal: classes.stepperHorizontal,
                          }}
                        >
                          {visibleStepIds.map((stepId, index) => (
                            <Step
                              key={stepId}
                              data-cy={`transactionStep-${stepId}-${
                                index > activeStepIndex ? 'pending' : 'done'
                              }`}
                            >
                              <StepLabel
                                StepIconComponent={ColorlibStepIcon}
                                StepIconProps={{
                                  visibleStepIds,
                                }}
                              >
                                {stepIdStepMap.get(stepId).label}
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                        {!transaction.isComplete && (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <span
                              className={classes.transactionNotificationText}
                            >
                              A member of the NOW Team will reach out shortly
                              with closing instructions
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Watermark>
                </>
              )}
              <Grid container className={classes.detailsContainer}>
                {/* This Watermark is set inside the detailsContainer so the
                zIndex can be set between the container and the placeBidButton */}
                <Watermark text={watermarkText} rotate="-20">
                  <Grid className={classes.descriptionContainer}>
                    <span>Description</span>
                    {isEitherTransaction && (
                      <div className={classes.sidebarContainer}>
                        <Button
                          data-cy="cashBidTypeButton"
                          className={classes.button}
                          variant="outlined"
                          style={{
                            ...(bidTypeSelection === TransactionTypes.Cash
                              ? { background: '#7890a6', color: '#fff' }
                              : {}),
                            borderRight: 'none',
                            borderRadius: '8px 0 0 8px',
                          }}
                          onClick={() => handleBidTypeSelectionChange(TransactionTypes.Cash)}
                        >
                          Cash
                        </Button>
                        <Button
                          data-cy="carryBidTypeButton"
                          className={classes.button}
                          style={{
                            ...(bidTypeSelection === TransactionTypes.Carry
                              ? { background: '#7890a6', color: '#fff' }
                              : {}),
                            borderLeft: 'none',
                            borderRadius: '0px 8px 8px 0px',
                          }}
                          variant="outlined"
                          onClick={() => handleBidTypeSelectionChange(TransactionTypes.Carry)}
                        >
                          Carry
                        </Button>
                      </div>
                    )}
                  </Grid>
                  {canBidOnProperty && !existingBid && (
                    <div className={classes.notificationText}>
                      <img src={notificationIcon} alt="notification" />
                      {`Enter your ${
                        bidTypeSelection === TransactionTypes.Carry
                          ? 'Carry'
                          : 'Cash'
                      } bid to continue`}
                    </div>
                  )}
                  <Grid
                    container
                    className={cx(
                      classes.bottomContentContainer,
                      classes.wellsDesktop,
                    )}
                  >
                    {propertyWells.map((item, index) => (
                      <Grid
                        item
                        container
                        direction="column"
                        key={index}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={3}
                        xl={3}
                        className={classes.bottomSectionHeading}
                      >
                        <Grid
                          item
                          className={classes.bottomSectionHeading}
                          container
                          direction="column"
                        >
                          <div>
                            Landing Zone
                            {index + 1}
                          </div>
                        </Grid>
                        <Grid
                          item
                          className={classes.bottomSectionHeading}
                          container
                          direction="column"
                        >
                          <div>Name</div>
                          <div className={classes.bottomSectionDescription}>
                            {item.wellName}
                          </div>
                        </Grid>
                        <Grid
                          item
                          className={classes.bottomSectionHeading}
                          container
                          direction="column"
                        >
                          <div>Working Interest</div>
                          <div className={classes.bottomSectionDescription}>
                            {item.workingInterestPercentage || ''}
                            %
                          </div>
                        </Grid>
                        <Grid
                          item
                          className={classes.bottomSectionHeading}
                          container
                          direction="column"
                        >
                          <div>Net Revenue Interest</div>
                          <div className={classes.bottomSectionDescription}>
                            {item.netRevenueInterestNumber || ''}
                            %
                          </div>
                        </Grid>
                        <Grid
                          item
                          className={classes.bottomSectionHeading}
                          container
                          direction="column"
                        >
                          <div>Well Gross AFE Amount</div>
                          <div className={classes.bottomSectionDescription}>
                            {formatNumberToCurrency(item.wellGrossAfe)}
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <div
                    className={classes.wellsContainer}
                    style={
                      isOwnerCompany || !canBidOnProperty
                        ? { gridTemplateColumns: 'repeat(10, 1fr)' }
                        : {}
                    }
                  >
                    <Grid className={classes.bottomSectionHeading}>
                      <div>Name</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>API Number</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>Landing Zone</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>Total Vertical Depth</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>Net Working Interest</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>8/8ths Revenue Interest</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <FormulaTooltip
                        title="Net Working Interest * 8/8ths Revenue Interest"
                        maxWidth="335px"
                      >
                        <div className={classes.infoIconCotainer}>
                          Net Revenue Interest
                          <InfoOutlinedIcon className={classes.infoIcon} />
                        </div>
                      </FormulaTooltip>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>Well Gross AFE</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <FormulaTooltip title="Net Working Interest * Well Gross AFE">
                        <div className={classes.infoIconCotainer}>
                          Well Net AFE
                          <InfoOutlinedIcon className={classes.infoIcon} />
                        </div>
                      </FormulaTooltip>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>
                        {`${
                          bidTypeSelection === TransactionTypes.Cash
                            ? 'Cash'
                            : 'Carry'
                        } Minimum Bid`}
                      </div>
                    </Grid>
                    {!isOwnerCompany && canBidOnProperty && (
                      <Grid
                        className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                        style={{ borderLeft: 'solid 1px #000000' }}
                      >
                        <div>Bid</div>
                      </Grid>
                    )}
                    {!isOwnerCompany && canBidOnProperty && (
                      <Grid
                        className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                      >
                        {bidTypeSelection === TransactionTypes.Carry ? (
                          <FormulaTooltip title="Net Working Interest * ( 1 - Carry Bid )">
                            <div className={classes.infoIconCotainer}>
                              Buyer Assigned Net WI
                              <InfoOutlinedIcon className={classes.infoIcon} />
                            </div>
                          </FormulaTooltip>
                        ) : (
                          <div>Buyer Assigned Net WI</div>
                        )}
                      </Grid>
                    )}
                    {!isOwnerCompany && canBidOnProperty && (
                      <Grid
                        className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                      >
                        {bidTypeSelection === TransactionTypes.Carry ? (
                          <FormulaTooltip title="Net Working Interest * Carry Bid">
                            <div className={classes.infoIconCotainer}>
                              Seller Retained Net WI
                              <InfoOutlinedIcon className={classes.infoIcon} />
                            </div>
                          </FormulaTooltip>
                        ) : (
                          <div>Seller Retained Net WI</div>
                        )}
                      </Grid>
                    )}
                  </div>

                  {propertyWells.map((item, index) => {
                    const existingBidPerWell = property?.bidPerWells?.find(
                      bid => +bid.wellId === +item.id,
                    );

                    const allBidsPerWell = findAll(
                      property?.bidPerWells,
                      bid => +bid.wellId === +item.id,
                    );
                    const cashBidOnWell = allBidsPerWell.find(
                      bid => bid.amount,
                    );
                    const carryBidOnWell = allBidsPerWell.find(
                      bid => bid.carryPercentage,
                    );

                    const acquiredPercentage = computeAcquiredRetainedInterest({
                      bidCarryPercentage: existingBid
                        ? existingBidPerWell?.carryPercentage
                        : bidTypeSelection === TransactionTypes.Carry
                          ? wellAllocationBids[item.id]
                          : undefined,
                      sellerOriginalOwnershipPercentage:
                        item.workingInterestPercentage,
                    });
                    return (
                      <React.Fragment key={item.id}>
                        <div
                          className={classes.wellsContainer}
                          style={
                            isOwnerCompany || !canBidOnProperty
                              ? { gridTemplateColumns: 'repeat(10, 1fr)' }
                              : {}
                          }
                        >
                          <Grid item className={classes.bottomSectionHeading}>
                            <div
                              data-cy={`wellName[${index}]`}
                              className={classes.bottomSectionDescription}
                            >
                              {item.wellName}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div
                              data-cy={`wellAPINumber[${index}]`}
                              className={classes.bottomSectionDescription}
                            >
                              {item.wellAPINumber || '-'}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div
                              data-cy={`landingZone[${index}]`}
                              className={classes.bottomSectionDescription}
                            >
                              {item?.landingZone?.title}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div
                              data-cy={`totalVerticalDepth[${index}]`}
                              className={classes.bottomSectionDescription}
                            >
                              {`${item?.wellTotalVerticalDepth} ft`}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div
                              data-cy={`workingInterestPercentage[${index}]`}
                              className={classes.bottomSectionDescription}
                            >
                              {item.workingInterestPercentage || 0}
                              %
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div
                              data-cy={`eightsCalculations[${index}]`}
                              className={classes.bottomSectionDescription}
                            >
                              {`${eightsCalculations(item)}%`}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div
                              data-cy={`netRevenueInterestNumber[${index}]`}
                              className={classes.bottomSectionDescription}
                            >
                              {item.netRevenueInterestNumber || 0}
                              %
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div
                              data-cy={`wellGrossAfe[${index}]`}
                              className={classes.bottomSectionDescription}
                            >
                              {formatNumberToCurrency(item.wellGrossAfe)}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div
                              data-cy={`wellNetAFE[${index}]`}
                              className={classes.bottomSectionDescription}
                            >
                              {formatNumberToCurrency(item.wellNetAFE)}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            {bidTypeSelection === TransactionTypes.Cash && (
                              <div
                                data-cy={`minimumBid[${index}]`}
                                className={classes.bottomSectionDescription}
                              >
                                {!item.minimumBid || item.minimumBid === '0'
                                  ? '-'
                                  : formatNumberToCurrency(
                                    String(item.minimumBid),
                                  )}
                              </div>
                            )}
                            {bidTypeSelection === TransactionTypes.Carry && (
                              <div
                                data-cy={`minimumBidCarry[${index}]`}
                                className={classes.bottomSectionDescription}
                              >
                                {!item.minimumBidCarry
                                || item.minimumBidCarry === '0'
                                  ? '-'
                                  : `${item.minimumBidCarry}%`}
                              </div>
                            )}
                          </Grid>
                          {!isOwnerCompany && canBidOnProperty && (
                            <>
                              <Grid
                                item
                                className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                                style={{ borderLeft: 'solid 1px #000000' }}
                              >
                                <div
                                  className={classes.bottomSectionDescription}
                                >
                                  {bidTypeSelection
                                  === TransactionTypes.Cash ? (
                                    <Grid
                                      item
                                      xs={12}
                                      key={item.id}
                                      className={classes.amountContainer}
                                    >
                                      {cashBidOnWell ? (
                                        <AmountInput
                                          data-cy={`cashBidAmount[${index}]`}
                                          disabled
                                          input={{
                                            value:
                                              allBidsPerWell.find(
                                                bid => bid.amount,
                                              )?.amount || '',
                                          }}
                                          meta={{ withoutErrorField: true }}
                                        />
                                      ) : (
                                        <AmountInput
                                          data-cy={`cashBidAmount[${index}]`}
                                          disabled={isOwnerCompany}
                                          input={{
                                            placeholder:
                                              '$ Enter your cash bid amount',
                                            onChange:
                                              handleCarryAmountChange(item),
                                            value:
                                              wellAllocationBids[item.id] || '',
                                          }}
                                          meta={{ withoutErrorField: true }}
                                        />
                                      )}
                                    </Grid>
                                    ) : (
                                      <Grid
                                        item
                                        xs={12}
                                        key={item.id}
                                        className={classes.amountContainer}
                                      >
                                        {carryBidOnWell ? (
                                          <AmountInput
                                            data-cy={`carryBidPercentage[${index}]`}
                                            disabled
                                            maskType="percent"
                                            input={{
                                              value:
                                              allBidsPerWell.find(
                                                bid => bid.carryPercentage,
                                              )?.carryPercentage || '',
                                            }}
                                            meta={{}}
                                          />
                                        ) : (
                                          <AmountInput
                                            data-cy={`carryBidPercentage[${index}]`}
                                            disabled={isOwnerCompany}
                                            maskType="percent"
                                            meta={{ withoutErrorField: true }}
                                            input={{
                                              placeholder:
                                              '% Enter your carry bid percentage',
                                              onChange:
                                              handleCarryAmountChange(item),
                                              value:
                                              wellAllocationBids[item.id] || '',
                                            }}
                                          />
                                        )}
                                      </Grid>
                                    )}
                                </div>
                              </Grid>
                              <Grid
                                item
                                className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                              >
                                <div
                                  data-cy={`buyerAcquiredPercentage[${index}]`}
                                  className={classes.bottomSectionDescription}
                                >
                                  {roundUpToTenDecimalPoints(
                                    acquiredPercentage.buyerAcquiredPercentage,
                                  )}
                                  %
                                </div>
                              </Grid>
                              <Grid
                                item
                                className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                              >
                                <div
                                  data-cy={`sellerRetainedPercentage[${index}]`}
                                  className={classes.bottomSectionDescription}
                                >
                                  {roundUpToTenDecimalPoints(
                                    acquiredPercentage.sellerRetainedPercentage,
                                  )}
                                  %
                                </div>
                              </Grid>
                            </>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                  {!isOwnerCompany && canBidOnProperty && (
                    <>
                      <div
                        className={classes.totalContainer}
                        style={{ width: 'fit-content', marginLeft: 'auto' }}
                      >
                        <TotalListingCost
                          transactionType={bidTypeSelection}
                          grantConsideration={grantConsideration}
                          closingCost={
                            isEitherTransaction
                              ? closingCostLegendForEither
                              : closingCostLegend
                          }
                          bid={
                            isEitherTransaction
                              ? currentBidForEither
                              : currentBid
                          }
                          totalCost={
                            isEitherTransaction
                              ? totalCostLegendForEither
                              : totalCostLegend
                          }
                        />
                      </div>
                      <div
                        className={classes.totalContainer}
                        style={{
                          width: 'fit-content',
                          margin: 5,
                          marginLeft: 'auto',
                        }}
                      >
                        Total bid does not includes buyer's AFE obligations
                      </div>
                      <Grid
                        component="form"
                        onSubmit={handleSubmit(onHandleSubmit)}
                        className={classes.bidWrapper}
                      >
                        {!hidePlaceBidButton && (
                          <>
                            <PlaceBidButton disabled={inputDisabled} />
                            <Grid item xs={12}>
                              <StyledLink
                                path="/increase-bid-allowance"
                                style={{ fontSize: '14px' }}
                              >
                                Increase Bid Allowance
                              </StyledLink>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                </Watermark>
              </Grid>
              {isPropertyArchivedAndBuyer() && (
                <Watermark text={watermarkText} rotate="-20">
                  <Grid container className={classes.detailsContainer}>
                    <Grid container alignItems="center">
                      <span className={classes.contentHeading}>
                        Bidding Details
                      </span>
                    </Grid>

                    <Grid
                      container
                      justifyContent="flex-start"
                      className={classes.bottomContentContainer}
                    >
                      <Grid
                        container
                        direction="column"
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={3}
                        xl={3}
                        className={cx(
                          classes.biddingDetailsSectionHeading,
                          classes.redText,
                        )}
                      >
                        <span>Time Remaining</span>
                        <span
                          className={classes.contentBorders}
                          style={{ marginTop: '8px', padding: '6.5px 0px' }}
                        >
                          <Countdown
                            className={classes.biddingDetailsSectionDescription}
                            onClose={() => dispatch(getCurrentProperty(listingId))}
                            endTime={
                              listingHasBeenClosed(property)
                                ? toAuctionEventTime(
                                  new Date(property.endTime),
                                  'closed',
                                )
                                  .toJSDate()
                                  .toISOString()
                                : toAuctionEventTime(
                                  new Date(property.endTime),
                                  'end',
                                )
                                  .toJSDate()
                                  .toISOString()
                            }
                            withoutLabel
                            withoutAdaptive
                          />
                        </span>
                      </Grid>

                      <Grid
                        container
                        direction="column"
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={3}
                        xl={3}
                        className={classes.biddingDetailsSectionHeading}
                      >
                        <span>End Time</span>
                        <span
                          className={cx(
                            classes.biddingDetailsSectionDescription,
                            classes.contentBorders,
                          )}
                        >
                          {`${nonOpWellsDateTime(
                            new Date(property.endTime),
                          ).toFormat('MM / dd / yyyy / t ZZZZ')}`}
                        </span>
                      </Grid>

                      <Grid
                        container
                        direction="column"
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={3}
                        xl={3}
                        className={classes.biddingDetailsSectionHeading}
                      >
                        <span>Total Gross AFE</span>
                        <span
                          className={cx(
                            classes.biddingDetailsSectionDescription,
                            classes.contentBorders,
                          )}
                        >
                          {totalWellGrossAfeFormatted}
                        </span>
                      </Grid>

                      <Grid
                        container
                        direction="column"
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={3}
                        xl={3}
                        className={classes.biddingDetailsSectionHeading}
                      >
                        <span>Total Net AFE</span>
                        <span
                          className={cx(
                            classes.biddingDetailsSectionDescription,
                            classes.contentBorders,
                          )}
                        >
                          {netAfeFormatted}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Watermark>
              )}
              {isPropertyArchivedAndBuyer() && (
                <Watermark text={watermarkText} rotate="-20">
                  <Grid container className={classes.detailsContainer}>
                    <Grid
                      container
                      direction="column"
                      className={classes.bottomSectionHeading}
                      style={{ border: 'none' }}
                    >
                      <Box className={classes.contentHeading}>
                        Recently Added Documents
                        <CheckCircleOutlineIcon className={classes.checkIcon} />
                      </Box>
                      <Box className={classes.contentSubHeading}>
                        Below are the recently added documents that have been
                        submitted by the seller after the listing was approved
                        for bidding.
                      </Box>
                      <DocumentsTable
                        listingId={property.id}
                        documents={property.documents.filter(document => Object.keys(
                          listingDocumentAdditionalTypeMap,
                        ).includes(document.type))}
                        onDownload={handleDocumentDownload}
                      />
                    </Grid>
                  </Grid>
                </Watermark>
              )}
              {isPropertyArchivedAndBuyer() && (
                <Watermark text={watermarkText} rotate="-20">
                  <Grid container className={classes.detailsContainer}>
                    <Grid
                      container
                      direction="column"
                      className={classes.bottomSectionHeading}
                      style={{ border: 'none' }}
                    >
                      <Box className={classes.contentHeading}>
                        Original Documents
                      </Box>
                      <Box className={classes.contentSubHeading}>
                        These are documents that have been submitted by the
                        seller prior to the listing being approved for bidding.
                      </Box>
                      <DocumentsTable
                        listingId={property.id}
                        documents={property.documents.filter(document => Object.keys(listingDocumentNewTypeMap).includes(
                          document.type,
                        ))}
                        onDownload={handleDocumentDownload}
                      />
                    </Grid>
                  </Grid>
                </Watermark>
              )}
              {isPropertyArchivedAndBuyer() && (
                <Grid container className={classes.mapContainer}>
                  {isUsingEsriMaps() ? (
                    <EsriMap
                      wells={wellsAugmented}
                      isShowWellsTable
                      isListingsMap
                      showNonopLayers={false}
                    />
                  ) : (
                    <GoogleMap wells={coordinates} />
                  )}
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

PropertyView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  property: PropTypes.object,
  user: PropTypes.shape({
    companyId: PropTypes.number,
    isAuthorizedSigner: PropTypes.bool,
    isViewOnlyListingEditor: PropTypes.bool,
    isAccountManager: PropTypes.bool,
    // TODO: [TYPE] fill out remaining fields
  }).isRequired,
  isAdminOrComplianceUser: PropTypes.bool,
};

PropertyView.defaultProps = {
  isAdminOrComplianceUser: false,
};

export default compose(
  reduxForm({
    form: 'makeBet',
  }),
  connect(({ propertyView, auth, company }) => ({
    hasBankInformation: auth.user.company?.hasBankInformation,
    hasBuyerAgreement: auth.user.company?.hasBuyerAgreement,
    companyIsApprovedAndActive:
      !!auth.user.company?.approved && !!auth.user.company?.active,
    property: propertyView.currentProperty,
    user: auth.user,
    isAdminOrComplianceUser: isAdminOrCompliancePerson(auth.user),
    userFullName: getUserFullName(auth.user),
    companyName: company.fullLegalCompanyName,
  })),
  withStyles(styles),
  formValues('amount', 'carryPercentage'),
  memo,
)(PropertyView);
