import { LOCATION_CHANGE } from 'connected-react-router';

// actions
import * as A from '../actions/mapActions';

const initialState = {
  pins: undefined,
  historicalListings: [],
  newMexicoListings: [],
  newMexicoListingDocuments: [],
  currentHistoricalData: [],
  currentNewMexicoData: [],
  historicalListingTable: [],
  newMexicoListingTable: [],
};

export const reducer = {
  map(state = initialState, action) {
    switch (action.type) {
      case LOCATION_CHANGE:
      case A.clearState.type:
        return initialState;

      case A.getPropertiesMarkers.success:
        return { ...state, pins: action.payload };

      case A.getHistoricalListings.success:
        return {
          ...state,
          historicalListings: action.payload,
          currentHistoricalData: action.payload,
          historicalListingTable: action.payload,
        };

      case A.getNewMexicoListings.success:
        return {
          ...state,
          newMexicoListings: action.payload,
          currentNewMexicoData: action.payload,
          newMexicoListingTable: action.payload,
        };

      case A.getNewMexicoListingDocuments.success:
        return { ...state, newMexicoListingDocuments: action.payload };

      case A.resetMap.type:
        return {
          ...state,
          currentHistoricalData: state.historicalListings,
          currentNewMexicoData: state.newMexicoListings,
          historicalListingTable: state.historicalListings,
          newMexicoListingTable: state.newMexicoListings,
        };

      case A.updateHistoricalData.type:
        return {
          ...state,
          currentHistoricalData: action.payload,
          historicalListingTable: action.payload,
        };

      case A.updateNewMexicoData.type:
        return {
          ...state,
          currentNewMexicoData: action.payload,
          newMexicoListingTable: action.payload,
        };

      case A.updateHistoricalListingTable.type:
        return { ...state, historicalListingTable: action.payload };

      case A.updateNewMexicoListingTable.type:
        return { ...state, newMexicoListingTable: action.payload };

      case A.updateMapLasso.type:
        return { ...state, lasso: action.payload };

      default:
        return state;
    }
  },
};
