import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getActivePropertiesCoordinates: makeAxiosRequest(
    '/get-active-properties-coordinates',
    { method: 'get' },
    true,
  ),
  getHistoricalListings: makeAxiosRequest(
    '/historical-transactions',
    { method: 'get' },
    true,
  ),
  getNewMexicoListings: makeAxiosRequest(
    '/new-mexico-transactions',
    { method: 'get' },
    true,
  ),
  getNewMexicoListingDocuments: makeAxiosRequest(
    '/new-mexico/documents',
    { method: 'get' },
    true,
  ),
};
