export const teamMemberNames = {
  russell: 'Russell McGowan',
  james: 'James Magee',
};

export const teamMemberContact = {
  russell: 'russ@nonopwells.com',
  james: 'james@nonopwells.com',
};

export const teamMemberBio = {
  jamesBio: 'James is a third-generation oil and gas professional and has spent over a decade working across multiple industry verticals, both in the lower 48 and Canada. Prior to co-founding NOW, he was a member of the Corporate Development Team at a public upstream company, focused on M&A and A&D. James’ other experience includes helping to manage an oil and gas public equities investment portfolio, as well as working in equity research and for a Canadian OFS company.',
  russellBio: 'Prior to co-founding NonOpWells, Russ served in various management roles for private E&P operators. At Torrent Oil, Russ served as a Vice President leading finance and business development efforts. Prior to his time at Torrent, Russ served as a financial consultant for both public and private E&P companies, assembled and led a Midland Basin-focused private E&P company, and ran finance and business development for a private operator in East Texas. Russ began his career in investment banking advising on capital markets, M&A, and A&D transactions.',
};
