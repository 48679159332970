import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import { useDispatch, connect } from 'react-redux';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// modals
import ChangePasswordModal from 'components/Modals/ChangePasswordModal';
import SuccessModal from 'now-frontend-shared/components/modals/SuccessModal';
import TextModal from 'now-frontend-shared/components/modals/TextModal';
import SignInModal from 'components/Modals/SignInModal';

// components
import Footer from 'components/Footer';
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import { changeAuthInfo, removeUserData } from 'store/actions/authActions';
import Header from './components/Header';
import TopSection from './components/TopSection';
import OverviewSection from './components/OverviewSection';
import TeamBioSection from './components/TeamBioSection';
import BottomSection from './components/BottomSection';
import SignupModal from 'components/Modals/SignupModal';
import FooterTerms from 'components/FooterTerms';

// custom hooks

// store

const MainPage = ({
  passwordChangeConfirmed,
  resetPasswordToken,
  tokensHasExpired,
  lastEmail,
  confirmedEmail,
  emailSent,
  userSignedUp,
  isAuthorized,
  location,
}) => {
  const { setModal } = useModalSetter();
  const dispatch = useDispatch();

  useEffect(() => {
    // Check url for query string register, if present, open register modal
    const urlParams = new URLSearchParams(window.location.search);
    const register = urlParams.get('modal');
    if (register) {
      setModal(<SignupModal />);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [setModal]);

  const lastEmailRef = useRef();
  lastEmailRef.current = lastEmail;

  useEffect(() => {
    if (userSignedUp) {
      setModal(
        <TextModal
          dataCy="activateAccountModal"
          heading="Activate your account"
          description="We just sent to you an activation link to complete email verification process."
        />,
      );
      dispatch(changeAuthInfo({ userSignedUp: false }));
    }
  }, [userSignedUp, setModal, dispatch]);

  useEffect(() => {
    if (emailSent) {
      setModal(
        <TextModal
          heading="E-mail was sent!"
          description="We have sent you an email to reset your password. Please check your inbox."
        />,
      );
      dispatch(changeAuthInfo({ emailSent: false }));
    }
  }, [emailSent, setModal, dispatch]);

  useEffect(() => {
    const handleSignIn = () => setModal(<SignInModal email={confirmedEmail || ''} />);
    const handleNext = resetPasswordToken
      ? () => {
        setModal(
          <ChangePasswordModal resetPasswordToken={resetPasswordToken} />,
          confirmedEmail && handleSignIn,
        );
        dispatch(removeUserData());
      }
      : handleSignIn;

    if (confirmedEmail) {
      setModal(
        <SuccessModal
          label={`Your email address has been verified successfully! ${
            resetPasswordToken
              ? 'Please set your password before logging in.'
              : 'Please login to your account.'
          }`}
        />,
        handleNext,
      );
      dispatch(removeUserData());
    } else if (resetPasswordToken) {
      handleNext();
    }
  }, [confirmedEmail, resetPasswordToken, setModal, dispatch]);

  useEffect(() => {
    if (passwordChangeConfirmed) {
      const email = lastEmailRef.current || '';
      setModal(
        <SuccessModal label="Your password has been changed successfully! Please login to your account." />,
        () => setModal(<SignInModal email={email} />),
      );
      dispatch(removeUserData());
    }
  }, [passwordChangeConfirmed, setModal, dispatch]);

  const next = location?.query?.next;
  const [shouldOpenModalForNext, setShouldOpenModalForNext] = useState(false);
  useEffect(() => {
    if (next && !isAuthorized) {
      setShouldOpenModalForNext(true);
    }
  }, [next, isAuthorized]);

  useEffect(() => {
    if (tokensHasExpired || shouldOpenModalForNext) {
      setModal(
        <SignInModal
          statusMessage={
            tokensHasExpired
              ? 'Due to inactivity you have been logged out.'
              : ''
          }
          email={lastEmailRef.current}
        />,
        () => dispatch(
          changeAuthInfo({
            tokensHasExpired: false,
            lastEmail: null,
          }),
        ),
      );
      setShouldOpenModalForNext(false);
    }
  }, [tokensHasExpired, shouldOpenModalForNext, setModal, dispatch]);

  return (
    <>
      <Header />
      <TopSection />
      <OverviewSection />
      <TeamBioSection />
      <BottomSection />
      <FooterTerms />
      <Footer />
    </>
  );
};

MainPage.propTypes = {
  passwordChangeConfirmed: PropTypes.bool,
  resetPasswordToken: PropTypes.string,
  tokensHasExpired: PropTypes.bool,
  lastEmail: PropTypes.string,
  confirmedEmail: PropTypes.string,
  emailSent: PropTypes.bool,
  userSignedUp: PropTypes.bool,
  isAuthorized: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

MainPage.defaultProps = {
  confirmedEmail: undefined,
  lastEmail: undefined,
  userSignedUp: undefined,
  isAuthorized: undefined,
};

export default compose(
  connect(({ auth, router }) => ({
    userSignedUp: auth.authInfo.userSignedUp,
    emailSent: auth.authInfo.emailSent,
    lastEmail: auth.authInfo.lastEmail,
    confirmedEmail: auth.authInfo.confirmedEmail,
    tokensHasExpired: auth.authInfo.tokensHasExpired,
    resetPasswordToken: auth.authInfo.resetPasswordToken,
    passwordChangeConfirmed: auth.authInfo.passwordChangeConfirmed,
    isAuthorized: auth.authInfo.isAuthorized,
    location: router.location,
  })),
  memo,
)(MainPage);
