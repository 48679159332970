import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import React, {
  memo,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';

// components
import {
  Subtitle2,
  cardElevation,
  cardRadius,
  firstMainRowGap,
  loadingMessage,
  notApplicableMessage,
} from 'pages/Dashboard';

// styles and components from material-ui
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

// helpers
import { formatNumberToCurrency } from 'now-shared/helpers/currency-helpers';
import { pluralize } from 'now-shared/helpers/text-helpers';
import { getCompanyName } from 'now-shared/helpers/company-helpers';

// store
import {
  getListingsSold,
  getSellerStatuses,
} from 'store/actions/myPropertiesActions';

import { SortDirection } from 'now-shared/enums/sort-direction';

const listingsSoldVisibleRowCount = 3;

const ListingsSold = props => {
  const {
    listings,
    listingsMeta,
    listingStatuses,
    isApplicable,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [listingsSoldPageNumber, setListingsSoldPageNumber] = useState(1);

  useEffect(() => {
    if (isApplicable && !listingStatuses) {
      dispatch(getSellerStatuses());
    }
  }, [dispatch, isApplicable, listingStatuses]);

  const soldStatus = useMemo(() => listingStatuses?.find(status => status.title === 'sold'), [listingStatuses]);

  useEffect(() => {
    if (isApplicable && soldStatus) {
      dispatch(getListingsSold({
        page: listingsSoldPageNumber,
        limit: listingsSoldVisibleRowCount,
        archived: true,
        statusIds: [
          soldStatus.id,
        ],
        order: 'endTime',
        sort: SortDirection.Descending,
      }));
    }
  }, [dispatch, listingsSoldPageNumber, isApplicable, soldStatus]);

  const loadingOrNotApplicableMessage = isApplicable ? loadingMessage : notApplicableMessage;

  const listingsSold = listings;

  return (
    <Card
      elevation={cardElevation}
      style={{
        width: '100%',
        height: 401,
        borderRadius: cardRadius,
        padding: firstMainRowGap,
        paddingBottom: 10,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontSize: 34,
            fontWeight: 700,
            lineHeight: '49px',
            letterSpacing: 0.25,
          }}
        >
          Listings Sold
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          style={{
            width: '100%',
            gap: 15,
          }}
        >
          {(() => {
            let result;
            if (listingsSold) {
              if (listingsSold.length) {
                result = listingsSold.map(listing => (
                  <Card
                    key={listing.id}
                    raised
                    elevation={cardElevation}
                    style={{
                      width: '100%',
                      /**
                       * minHeight must be set to height, otherwise height will be ignored in some cases
                       */
                      minHeight: 80,
                      height: 80,
                      boxSizing: 'border-box',
                      borderRadius: cardRadius,
                    }}
                  >
                    <CardActionArea
                      onClick={() => history.push(`/listings/${listing.id}`)}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        style={{
                          paddingTop: 14,
                          paddingBottom: 14,
                          paddingLeft: 20,
                          paddingRight: 20,
                          width: '100%',
                          height: '100%',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Box
                          display="flex"
                          flexGrow={1}
                          flexDirection="column"
                          justifyContent="center"
                          style={{
                            height: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                          }}
                        >
                          <Box
                            style={{
                              overflow: 'hidden',
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {listing.projectName}
                              {' '}
                              (
                              {`${listing.wellCount} well${pluralize('s', listing.wellCount)}`}
                              )
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              overlow: 'hidden',
                            }}
                          >
                            <Typography
                              color="textSecondary"
                              style={{
                                fontSize: 13.5,
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: 0.08,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {listing.county.title}
                              {', '}
                              {listing.state.title}
                              {' - '}
                              {listing.operatorName}
                            </Typography>
                          </Box>
                        </Box>
                        {/* net afe amount */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            /**
                              * minWidth must be set to width, otherwise width will be ignored in some cases
                              */
                            minWidth: 130,
                            width: 130,
                            height: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                            textAlign: 'center',
                          }}
                        >
                          <Typography
                            variant="h6"
                          >
                            {
                              formatNumberToCurrency(
                                Math.round(
                                  +listing.netAfe,
                                ),
                              )
                            }
                          </Typography>
                          <Subtitle2>
                            Net AFE Amount
                          </Subtitle2>
                        </Box>
                        {/* bid count */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            /**
                              * minWidth must be set to width, otherwise width will be ignored in some cases
                              */
                            minWidth: 39,
                            width: 39,
                            height: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                            textAlign: 'center',
                          }}
                        >
                          <Typography
                            variant="h6"
                          >
                            {listing.bidCount}
                          </Typography>
                          <Subtitle2>
                            Bids
                          </Subtitle2>
                        </Box>
                        {/* winning bid amount */}
                        {/* TODO: show winning carryPercentage if it's a bid carry */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            /**
                              * minWidth must be set to width, otherwise width will be ignored in some cases
                              */
                            minWidth: 130,
                            width: 130,
                            height: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                            textAlign: 'center',
                          }}
                        >
                          <Typography
                            variant="h6"
                          >
                            {
                              formatNumberToCurrency(
                                Math.round(
                                  listing.winningOrHighestBid?.amount || 0,
                                ),
                              )
                            }
                          </Typography>
                          <Subtitle2>
                            Winning Bid
                          </Subtitle2>
                        </Box>
                        {/* winning buyer */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            /**
                              * minWidth must be set to width, otherwise width will be ignored in some cases
                              */
                            minWidth: 220,
                            width: 220,
                            height: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                            textAlign: 'center',
                          }}
                        >
                          <Box
                            style={{
                              width: '100%',
                              overflowY: 'visible',
                            }}
                          >
                            <Box
                              style={{
                                width: '100%',
                                overflowX: 'hidden',
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {getCompanyName(listing?.winningOrHighestBid?.user.company)}
                              </Typography>
                            </Box>
                          </Box>
                          <Subtitle2>
                            Winning Buyer
                          </Subtitle2>
                        </Box>
                      </Box>
                    </CardActionArea>
                  </Card>
                ));
              } else {
                result = 'none';
              }
            } else {
              result = loadingOrNotApplicableMessage;
            }
            return result;
          })()}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
        >
          <Pagination
            count={listingsMeta?.totalPages || 0}
            page={listingsSoldPageNumber}
            onChange={(_event, page) => setListingsSoldPageNumber(page)}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default compose(
  connect(({ myProperties }) => ({
    listings: myProperties.listingsSold?.items,
    listingsMeta: myProperties.listingsSold?.meta,
    listingStatuses: myProperties.statuses,
  })),
  memo,
)(ListingsSold);
