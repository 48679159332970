import React, { useEffect, useMemo } from 'react';
import { useDispatch, connect } from 'react-redux';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import Spinner from 'now-frontend-shared/components/Spinner';

// layouts
import HeadSection from 'now-frontend-shared/layouts/AuthSections/HeadSection';

// helpers
import {
  DEFAULT_QUERY,
  enhanceListingAnalytics,
  filterByDate,
} from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@mui/x-data-grid';

// styles
import { getPropertyBids, clearState } from 'store/actions/propertyBidsListActions';
import styles from './styles';

import { SortDirection } from 'now-shared/enums/sort-direction';
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import {
  clearListingAnalyticsState,
  getListingAnalytics,
} from 'store/actions/listingAnalyticsActions';
import { pluralize } from 'now-shared/helpers/text-helpers';
import { HighLevelStatCard, minSpaceBetweenStatCardsHalf } from 'now-frontend-shared/components/StatCard';
import { AnalyticsBarGraph } from 'now-frontend-shared/components/AnalyticsBarGraph';

const statCardRowMarginSides = 32;

const ListingAnalyticsPage = ({
  classes,
  match,
  commonInfo,
  propertyInfoIsLoading,
  analytics,
  analyticsIsLoading,
}) => {
  const dispatch = useDispatch();
  const [filterByCompany, setFilterByCompany] = React.useState(null);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);

  const listingId = match.params.id;
  // TODO: [PERFORMANCE] fetch the base listing info without querying for bids
  const query = `${DEFAULT_QUERY}&order=amount&sort=${SortDirection.Descending}`;

  useEffect(() => {
    dispatch(getListingAnalytics({
      listingId,
    }));

    return () => dispatch(clearListingAnalyticsState());
  }, [dispatch, listingId]);

  useEffect(() => {
    dispatch(getPropertyBids({
      id: listingId,
      query,
    }));

    return () => dispatch(clearState());
  }, [dispatch, listingId, query]);

  const { dateRange, enhancedAnalytics } = useMemo(
    () => enhanceListingAnalytics({ listing: commonInfo, analytics }),
    [analytics, commonInfo],
  );

  if (!commonInfo) {
    return <Spinner wrapped />;
  }

  const validateNotified = (row, companies) => {
    const { id } = row;
    const company = companies.find(c => c.id === id);
    return company?.views.length ? 'Yes' : 'No';
  };

  return (
    <>
      <Grid container className={classes.relativeContainer}>
        {(analyticsIsLoading || propertyInfoIsLoading) && <Spinner backdrop />}
        <Grid container direction="column" className={classes.wrapper}>
          <HeadSection
            heading={(
              <>
                <span>
                  Reports & Analytics Details
                  <br />
                </span>
                <span>
                  {`Listing-${commonInfo.id} | ${commonInfo.projectName} (${commonInfo.wells.length} well${pluralize('s', commonInfo.wells.length)})`}
                </span>
              </>
            )}
            goBack
            ignorePrevPath
            path={`/listings/${listingId}`}
          />
          <Grid
            item
            xs={12}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              style={{
                paddingLeft: statCardRowMarginSides - minSpaceBetweenStatCardsHalf,
                paddingRight: statCardRowMarginSides - minSpaceBetweenStatCardsHalf,
                paddingTop: statCardRowMarginSides,
                paddingBottom: statCardRowMarginSides,
              }}
            >
              <HighLevelStatCard
                data-cy="listingAnalyticsTotalViews"
                title="Total Views"
                value={enhancedAnalytics?.totalViews}
              />
              <HighLevelStatCard
                data-cy="listingAnalyticsTotalDownloads"
                title="Total Downloads"
                value={enhancedAnalytics?.totalDownloads}
              />
              <HighLevelStatCard
                dataCy="listingAnalyticsTotalBids"
                title="Total Bids"
                value={enhancedAnalytics?.totalBids}
              />
            </Box>
          </Grid>
          <Grid container direction="row">
            {!enhancedAnalytics?.companies.length ? (
              <Grid container justifyContent="center">
                <span className={classes.message}>No activity</span>
              </Grid>
            ) : (
              <div data-cy="analyticsTable">
                <Grid
                  item
                  xs={12}
                >
                  <AnalyticsBarGraph
                    filterByCompany={filterByCompany}
                    analytics={enhancedAnalytics}
                    dateRange={dateRange}
                    selectedDate={selectedDate}
                    setFilterByCompany={setFilterByCompany}
                    setSelectionModel={setSelectionModel}
                    setSelectedDate={setSelectedDate}
                  />
                </Grid>
                <DataGrid
                  rows={filterByDate(enhancedAnalytics.companies, selectedDate, dateRange.usingWeeks)}
                  autoHeight
                  autoPageSize
                  disableColumnMenu
                  disableColumnSelector
                  onRowClick={({ id, row }) => setFilterByCompany({ id, name: row.fullLegalCompanyName })}
                  onSelectionModelChange={newSelectionModel => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  columns={[
                    {
                      field: 'companyName',
                      headerName: 'COMPANY',
                      flex: 1,
                      valueGetter: ({ row }) => getCompanyName(row),
                    },
                    {
                      field: 'isNotified',
                      headerName: 'NOTIFIED',
                      flex: 1,
                      valueGetter: ({ row }) => (
                        // eslint-disable-next-line no-nested-ternary
                        row.isCustomAnalytic
                          ? row.isNotified ? 'Yes' : 'No'
                          : validateNotified(row, filterByDate(enhancedAnalytics.companies, null, dateRange.usingWeeks))
                      ),
                    },
                    {
                      field: 'views',
                      headerName: 'VIEWS',
                      flex: 1,
                      valueGetter: ({ row }) => row.views.length,
                    },
                    {
                      field: 'downloads',
                      headerName: 'DOWNLOADS',
                      flex: 1,
                      valueGetter: ({ row }) => row.downloads.length,
                    },
                    {
                      field: 'hasBid',
                      headerName: 'BIDS',
                      flex: 1,
                      valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
                    },
                  ]}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ListingAnalyticsPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  analytics: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      fullLegalCompanyName: PropTypes.string.isRequired,
      operatingName: PropTypes.string,
      views: PropTypes.number.isRequired,
      downloads: PropTypes.number.isRequired,
      hasBid: PropTypes.bool.isRequired,
    })).isRequired,
  }),
  analyticsIsLoading: PropTypes.bool.isRequired,
  commonInfo: PropTypes.shape({
    // TODO: [TYPE] add specific field types
  }),
  propertyInfoIsLoading: PropTypes.bool.isRequired,
};

ListingAnalyticsPage.defaultProps = {
  analytics: undefined,
  commonInfo: undefined,
};

export default compose(
  connect(({ propertyBidsList, listingAnalytics }) => ({
    commonInfo: propertyBidsList.commonInfo,
    propertyInfoIsLoading: propertyBidsList.pending,
    analytics: listingAnalytics.analytics,
    analyticsIsLoading: listingAnalytics.analyticsIsLoading,
  })),
  withStyles(styles),
)(ListingAnalyticsPage);
