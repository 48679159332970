import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';
import Success from '../../components/Success';
import { connect, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { clearState } from 'store/actions/companyActions';
import { clearState as userClearState } from 'store/actions/userActions';

function ApprovalStatus({ user, classes }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearState());
    dispatch(userClearState());
  }, [dispatch]);
  return (
    user?.approvalStatus?.title !== 'rejected' ? (
      <div>
        <Success
          title="Congrats!"
          message="You have successfully submitted your information. We are reviewing your application and will contact you as soon as possible."
        >
          <Link className={classes.homeButton} to="/" variant="outlined">
            GO TO HOMEPAGE
          </Link>
        </Success>
      </div>
    ) : (
      <div className={classes.root}>
        <Grid
          style={{ padding: '100px 0' }}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Typography variant="h2" className={classes.title}>
            Application Update
          </Typography>
          <Typography variant="body1" className={classes.text}>
            Unfortunately your application has been rejected. Please contact us if you have any questions or concerns.
          </Typography>
          <Link className={classes.homeButton} to="/contact-us" variant="outlined">
            CONTACT US
          </Link>
        </Grid>
      </div>
    )
  );
}

ApprovalStatus.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default compose(
  connect(({ auth, company }) => ({
    user: auth.user,
    company,
  })),
  withStyles(styles),
)(ApprovalStatus);
