import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import React, {
  memo, useEffect, useState, useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';

// components
import {
  Subtitle2,
  cardElevation,
  cardRadius,
  firstMainRowGap,
  loadingMessage,
  notApplicableMessage,
} from 'pages/Dashboard';

// styles and components from material-ui
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

// helpers
import { pluralize } from 'now-shared/helpers/text-helpers';

// store
import {
  getListingsForAuctionsEnded,
  getSellerStatuses,
} from 'store/actions/myPropertiesActions';
import {
  getListingActionRequired,
} from '../SellerActionNeeded';

import { SortDirection } from 'now-shared/enums/sort-direction';

const auctionsEndedVisibleRowCount = 3;

const AuctionsEnded = props => {
  const {
    listings, listingsMeta, listingStatuses, isApplicable,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const [auctionsEndedPageNumber, setAuctionsEndedPageNumber] = useState(1);

  useEffect(() => {
    if (isApplicable && !listingStatuses) {
      dispatch(getSellerStatuses());
    }
  }, [dispatch, isApplicable, listingStatuses]);

  const closedStatus = useMemo(
    () => listingStatuses?.find(status => status.title === 'closed'),
    [listingStatuses],
  );
  const winnerSelectedStatus = useMemo(
    () => listingStatuses?.find(status => status.title === 'winner selected'),
    [listingStatuses],
  );

  useEffect(() => {
    if (isApplicable && closedStatus && winnerSelectedStatus) {
      dispatch(
        getListingsForAuctionsEnded({
          page: auctionsEndedPageNumber,
          limit: auctionsEndedVisibleRowCount,
          archived: false,
          statusIds: [closedStatus.id, winnerSelectedStatus.id],
          order: 'endTime',
          sort: SortDirection.Descending,
        }),
      );
    }
  }, [
    dispatch,
    auctionsEndedPageNumber,
    isApplicable,
    closedStatus,
    winnerSelectedStatus,
  ]);

  const loadingOrNotApplicableMessage = isApplicable
    ? loadingMessage
    : notApplicableMessage;

  const auctionsEnded = useMemo(
    () => listings?.map(listing => ({
      ...listing,
      actionRequired: getListingActionRequired(listing),
    })),
    [listings],
  );

  return (
    <Card
      elevation={cardElevation}
      style={{
        width: '100%',
        height: 401,
        borderRadius: cardRadius,
        padding: firstMainRowGap,
        paddingBottom: 10,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontSize: 34,
            fontWeight: 700,
            lineHeight: '49px',
            letterSpacing: 0.25,
          }}
        >
          Auctions Ended
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          style={{
            width: '100%',
            gap: 15,
          }}
        >
          {(() => {
            let result;
            if (auctionsEnded) {
              if (auctionsEnded.length) {
                result = auctionsEnded.map(listing => (
                  <Card
                    key={listing.id}
                    raised
                    elevation={cardElevation}
                    style={{
                      width: '100%',
                      /**
                       * minHeight must be set to height, otherwise height will be ignored in some cases
                       */
                      minHeight: 80,
                      height: 80,
                      boxSizing: 'border-box',
                      borderRadius: cardRadius,
                    }}
                  >
                    <CardActionArea
                      onClick={() => {
                        if (listing.actionRequired === 'Select Winner') {
                          history.push(`/listings/${listing.id}/bids`);
                        } else {
                          history.push(`/listings/${listing.id}`);
                        }
                      }}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        style={{
                          paddingTop: 14,
                          paddingBottom: 14,
                          paddingLeft: 20,
                          paddingRight: 20,
                          width: '100%',
                          height: '100%',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Box
                          display="flex"
                          flexGrow={1}
                          flexDirection="column"
                          justifyContent="center"
                          style={{
                            height: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                          }}
                        >
                          <Box
                            style={{
                              overflow: 'hidden',
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {listing.projectName}
                              {' '}
                              (
                              {`${listing.wellCount} well${pluralize(
                                's',
                                listing.wellCount,
                              )}`}
                              )
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              overlow: 'hidden',
                            }}
                          >
                            <Typography
                              color="textSecondary"
                              style={{
                                fontSize: 13.5,
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: 0.08,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {listing.county.title}
                              {', '}
                              {listing.state.title}
                              {' - '}
                              {listing.operatorName}
                            </Typography>
                          </Box>
                        </Box>
                        {/* bid count */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            /**
                             * minWidth must be set to width, otherwise width will be ignored in some cases
                             */
                            minWidth: 39,
                            width: 39,
                            height: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6">
                            {listing.bidCount}
                          </Typography>
                          <Subtitle2>Bids</Subtitle2>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            width: 150,
                            minWidth: 150,
                            height: '100%',
                            marginLeft: 40,
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                            textAlign: 'center',
                            color: theme.palette.primary.main,
                          }}
                        >
                          {listing.actionRequired && (
                            <Typography
                              style={{
                                fontSize: 15,
                                fontWeight: 700,
                                lineHeight: '22px',
                                letterSpacing: 0.46,
                                textTransform: 'uppercase',
                              }}
                            >
                              {listing.actionRequired}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </CardActionArea>
                  </Card>
                ));
              } else {
                result = 'none';
              }
            } else {
              result = loadingOrNotApplicableMessage;
            }
            return result;
          })()}
        </Box>
        <Box display="flex" justifyContent="center">
          <Pagination
            count={listingsMeta?.totalPages || 0}
            page={auctionsEndedPageNumber}
            onChange={(_event, page) => setAuctionsEndedPageNumber(page)}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default compose(
  connect(({ myProperties }) => ({
    listings: myProperties.listingsForAuctionsEnded?.items,
    listingsMeta: myProperties.listingsForAuctionsEnded?.meta,
    listingStatuses: myProperties.statuses,
  })),
  memo,
)(AuctionsEnded);
