import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import React, {
  memo, useEffect, useState, useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';

// components
import {
  Subtitle2,
  cardElevation,
  cardRadius,
  firstMainRowGap,
  loadingMessage,
  notApplicableMessage,
} from 'pages/Dashboard';

// styles and components from material-ui
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

// helpers
import { doesListingHaveTransactionInProgress } from 'now-shared/helpers/escrow-transaction-helpers';
import { pluralize } from 'now-shared/helpers/text-helpers';

// store
import { getListingsForSellerActionNeeded } from 'store/actions/myPropertiesActions';

export const showZeroHighestBidWhenNoBids = false;

export function getListingActionRequired(listing) {
  let actionRequired;
  if (doesListingHaveTransactionInProgress(listing)) {
    actionRequired
      = listing?.closingMethodId === 2
        ? 'Complete Transaction'
        : 'Complete Escrow';
  } else if (listing.status.title === 'closed') {
    if (listing.bidCount > 0) {
      actionRequired = 'Select Winner';
    } else {
      actionRequired = 'Archive/Reopen';
    }
  }
  return actionRequired;
}

const sellerActionsRequiredVisibleRowCount = 3;

const SellerActionNeeded = props => {
  const { listings, listingsMeta, isApplicable } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const [actionsRequiredPageNumber, setActionsRequiredPageNumber] = useState(1);

  useEffect(() => {
    if (isApplicable) {
      const params = {
        page: actionsRequiredPageNumber,
        limit: sellerActionsRequiredVisibleRowCount,
        archived: false,
      };
      dispatch(getListingsForSellerActionNeeded(params));
    }
  }, [dispatch, actionsRequiredPageNumber, isApplicable]);

  const actionsRequired = useMemo(
    () => listings?.map(listing => ({
      ...listing,
      actionRequired: getListingActionRequired(listing),
    })),
    [listings],
  );

  const loadingOrNotApplicableMessage = isApplicable
    ? loadingMessage
    : notApplicableMessage;

  return (
    <Card
      elevation={cardElevation}
      style={{
        width: '100%',
        height: 401,
        borderRadius: cardRadius,
        padding: firstMainRowGap,
        paddingBottom: 10,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontSize: 34,
            fontWeight: 700,
            lineHeight: '49px',
            letterSpacing: 0.25,
          }}
        >
          Action Needed
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          style={{
            width: '100%',
            gap: 15,
          }}
        >
          {(() => {
            let result;
            if (actionsRequired) {
              if (actionsRequired.length) {
                result = actionsRequired.map(listing => (
                  <Card
                    key={listing.id}
                    raised
                    elevation={cardElevation}
                    style={{
                      width: '100%',
                      /**
                       * minHeight must be set to height, otherwise height will be ignored in some cases
                       */
                      minHeight: 80,
                      height: 80,
                      boxSizing: 'border-box',
                      borderRadius: cardRadius,
                    }}
                  >
                    <CardActionArea
                      onClick={() => {
                        if (listing.actionRequired === 'Select Winner') {
                          history.push(`/listings/${listing.id}/bids`);
                        } else {
                          history.push(`/listings/${listing.id}`);
                        }
                      }}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        style={{
                          paddingTop: 14,
                          paddingBottom: 14,
                          paddingLeft: 20,
                          paddingRight: 20,
                          width: '100%',
                          height: '100%',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Box
                          display="flex"
                          flexGrow={1}
                          flexDirection="column"
                          justifyContent="center"
                          style={{
                            height: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                          }}
                        >
                          <Box
                            style={{
                              overflow: 'hidden',
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {listing.projectName}
                              {' '}
                              (
                              {`${listing.wellCount} well${pluralize(
                                's',
                                listing.wellCount,
                              )}`}
                              )
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              overlow: 'hidden',
                            }}
                          >
                            <Typography
                              color="textSecondary"
                              style={{
                                fontSize: 13.5,
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: 0.08,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {listing.county.title}
                              {', '}
                              {listing.state.title}
                              {' - '}
                              {listing.operatorName}
                            </Typography>
                          </Box>
                        </Box>
                        {/* bid count */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            /**
                             * minWidth must be set to width, otherwise width will be ignored in some cases
                             */
                            minWidth: 39,
                            width: 39,
                            height: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6">
                            {listing.bidCount}
                          </Typography>
                          <Subtitle2>Bids</Subtitle2>
                        </Box>
                        {/* action needed */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            width: 150,
                            minWidth: 150,
                            height: '100%',
                            marginLeft: 40,
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                            textAlign: 'center',
                            color: theme.palette.primary.main,
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 15,
                              fontWeight: 700,
                              lineHeight: '22px',
                              letterSpacing: 0.46,
                              textTransform: 'uppercase',
                              whiteSpace: 'normal',
                            }}
                          >
                            {listing.actionRequired}
                          </Typography>
                        </Box>
                      </Box>
                    </CardActionArea>
                  </Card>
                ));
              } else {
                result = 'none';
              }
            } else {
              result = loadingOrNotApplicableMessage;
            }
            return result;
          })()}
        </Box>
        <Box display="flex" justifyContent="center">
          <Pagination
            count={listingsMeta?.totalPages || 0}
            page={actionsRequiredPageNumber}
            onChange={(_event, page) => setActionsRequiredPageNumber(page)}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default compose(
  connect(({ myProperties }) => ({
    listings: myProperties.listingsForSellerActionNeeded?.items,
    listingsMeta: myProperties.listingsForSellerActionNeeded?.meta,
  })),
  memo,
)(SellerActionNeeded);
