export default {
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    width: '100%',
    borderRadius: '4px 4px 4px 4px',
    boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2), 
    0px 4px 5px rgba(0, 0, 0, 0.14), 
    0px 1px 10px rgba(0, 0, 0, 0.12)`,
    margin: '10px 0px 10px 0px',
    transition: 'all 0.05s linear',

    '&:hover': {
      borderRadius: '4px 4px 4px 4px',
      boxShadow: `0px 4px 4px 4px rgba(0, 0, 0, 0.1), 
    0px 8px 5px 4px rgba(0, 0, 0, 0.07), 
    0px 2px 10px 4px rgba(0, 0, 0, 0.06)`,
      margin: '10px 0px 10px 0px',
      transition: 'all 0.05s linear',
      transform: 'scale(1.007, 1.007)',
    },
  },

  values: {
    flex: '1',
    justifyContent: 'flex-end',
    gap: '20px',
    paddingRight: '36px',
  },

  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
  },

  headerLeft: {
    maxHeight: 100,
    padding: 0,
    margin: 0,
    display: 'flex',
    width: '40%',
  },

  headerLeftMain: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginTop: 5,
  },

  headerRight: {
    display: 'flex',
    width: 'fit-content',
    justifyContent: 'space-between',
    marginLeft: 5,
    alignItems: 'center',
    borderLeft: 'solid 1px #E5E5E5',
    paddingLeft: '25px',
  },

  headerActions: {
    alignItems: 'center',
    height: 30,
    marginRight: 10,
    display: 'flex',
  },

  winnerButton: {
    padding: 2,
    height: 20,
    borderColor: 'green',
    color: 'green',
    marginRight: 10,
  },

  status: {
    textTransform: 'uppercase',
    color: '#ffffff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    letterSpacing: '0.4px',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    transform: 'rotate3d(0, 0, 1, 180deg)',
    overflowWrap: 'anywhere',
    width: '61px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },

  cash: {
    background: '#87CC9B',
  },

  carry: {
    background: '#B29E78',
  },

  topRow: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
  },

  bottomRow: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
  },

  titleLink: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationThickness: '1px',
    },
  },

  bottomContentContainer: {
    padding: '22px 0',

    '@media screen and (max-width: 960px)': {
      padding: '10px 0',
    },
  },

  wellsDesktop: {
    '@media screen and (min-width: 601px)': {
      display: 'none',
    },
  },

  bottomSectionHeading: {
    padding: '6px',
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#000000',
    borderRight: 'solid 1px #E5E5E5',
    borderBottom: 'solid 1px #E5E5E5',
    borderTop: 'solid 1px #E5E5E5',

    '@media screen and (max-width: 960px)': {
      padding: '12px 0',
    },
  },

  bottomSectionDescription: {
    marginTop: '8px',
    fontWeight: 'normal',
  },

  wellsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(14, 1fr)',
  },

  darkerTile: {
    background: '#F9F9F9',
  },
  companyNameContainer: {
    width: '270px',
    height: '89px',
  },
  companyNameText: {
    fontSize: '24px',
    lineHeight: '1.1',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 3, // Set to 3 for the logic to set the longCompanyNameText class. This class will not exceed 2 lines
    '-webkit-box-orient': 'vertical',
  },
  longCompanyNameText: {
    fontSize: '1.2rem',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
};
