import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import newTheme from 'themes/theme';

const terms = {
  margin: '8px 0',
  fontSize: '12px',
  fontWeight: '600',
  textAlign: 'justify',
};

const FooterTerms = () => (
  <ThemeProvider theme={newTheme}>
    <CssBaseline />
    <Grid
      component="footer"
      container
      style={{
        backgroundColor: newTheme.palette.primary.light,
      }}
    >
      <main
        style={{
          padding: '20px 75px',
          borderBottom: 'solid 1px lightgray',
          textTransform: 'uppercase',
        }}
      >
        <p style={terms}>
          NONOPWELLS, INC. (“NOW”) is not a FINRA member. Securities are
          offered through PEP Advisory LLC, d/b/a Pickering Energy Partners
          (“PEP”), with which NOW has an independent, registered representative,
          business and software license agreement, for purposes of offering oil
          and gas interests to institutional-only customers through NOW’s
          proprietary web-based, sealed bid auction marketplace.
        </p>
        <p style={terms}>
          PEP Advisory LLC, which provides compliance support and supervision to
          NOW, is a member of
          {' '}
          <a
            href="https://www.finra.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            FINRA
          </a>
          . PEP Advisory LLC is also a member of the
          {' '}
          <a
            href="https://www.sipc.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Securities Investor Protection Corporation (SIPC)
          </a>
          . You may obtain information about SIPC, including the SIPC brochure,
          by contacting SIPC at (202) 371-8300. More information is available at
          {' '}
          <a
            href="https://brokercheck.finra.org/firm/summary/166636"
            target="_blank"
            rel="noopener noreferrer"
          >
            BrokerCheck
          </a>
          .
        </p>
        <p style={terms}>
          DISCLAIMER: THE INFORMATION CONTAINED IN THIS WEBSITE IS FOR
          INFORMATIONAL PURPOSES ONLY AND IS NOT INTENDED, AND SHOULD NOT BE
          USED OR CONSTRUED, AS AN OFFER TO SELL, OR A SOLICITATION OF ANY OFFER
          TO BUY, ANY SECURITY. NO SUCH OFFER OR SOLICITATION MAY BE MADE PRIOR
          TO THE DELIVERY OF DEFINITIVE OFFERING DOCUMENTATION. THE INFORMATION
          IN THIS WEBSITE IS NOT INTENDED AND SHOULD NOT BE CONSTRUED AS
          INVESTMENT, FINANCIAL, OR OTHER ADVICE.
        </p>
      </main>
    </Grid>
  </ThemeProvider>
);

export default FooterTerms;
