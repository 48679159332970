import { compose } from 'redux';
import {
  generalRules,
  signUpRules,
  addError,
  maxCharCount,
  hasValue,
} from 'now-shared/validation/validation-rules';
import validateFormattedCurrency from 'now-shared/validation/validateFormattedCurrency';
import validateBidAllowance from 'now-frontend-shared/validation/validateBidAllowance';

const signInValidator = values => (errors, fieldName) => {
  const value = values[fieldName];

  switch (fieldName) {
    case 'email':
      return compose(
        addError(fieldName, generalRules.email(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'password':
      return compose(
        // TODO: [VALIDATION] fix this or remove if unused
        // addError(fieldName, signInRules.password(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    default:
      return errors;
  }
};

const bankInformationValidator = values => (errors, fieldName) => {
  const value = values[fieldName];

  switch (fieldName) {
    case 'bankName':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'bankerName':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'bankerEmail':
      return compose(
        addError(fieldName, generalRules.email(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'bankerPhoneNumber':
      return compose(
        addError(fieldName, generalRules.phoneNumber(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'bankerAddress':
      return compose(
        addError(fieldName, generalRules.longFieldMaxLength(value)),
        hasValue(value)
          ? addError(fieldName, generalRules.minLength(value))
          : e => e,
      )(errors);
    case 'notes':
      return compose(
        hasValue(value)
          ? addError(fieldName, maxCharCount(2000)(value))
          : e => e,
      )(errors);
    case 'requestedBidAllowance':
      return compose(
        addError(fieldName, validateBidAllowance(value)),
      )(errors);
    default:
      return errors;
  }
};

const updateAccountValidator = values => (errors, fieldName) => {
  const value = values[fieldName];

  switch (fieldName) {
    case 'firstName':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'lastName':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'company':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'department':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'jobTitle':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'phoneNumber':
      return compose(
        addError(fieldName, generalRules.phoneNumber(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'email':
      return compose(
        addError(fieldName, generalRules.email(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    default:
      return errors;
  }
};

const contactUsValidator = values => (errors, fieldName) => {
  const value = values[fieldName];

  switch (fieldName) {
    case 'name':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'phoneNumber':
      return compose(
        addError(fieldName, generalRules.phoneNumber(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'email':
      return compose(
        addError(fieldName, generalRules.email(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'subject':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'text':
      return compose(
        addError(fieldName, generalRules.longFieldMaxLength(value)),
        addError(fieldName, generalRules.minLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);

    default:
      return errors;
  }
};

const settingsValidator = values => (errors, fieldName) => {
  const value = values[fieldName];

  switch (fieldName) {
    case 'oldPassword':
      return compose(
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'password':
      return compose(
        addError(fieldName, signUpRules.password(value)),
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'confirmPassword':
      return compose(
        addError(fieldName, generalRules.same(value, values.password)),
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);

    default:
      return errors;
  }
};

const changePasswordValidator = values => (errors, fieldName) => {
  const value = values[fieldName];

  switch (fieldName) {
    case 'password':
      return compose(
        addError(fieldName, signUpRules.password(value)),
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);
    case 'confirmPassword':
      return compose(
        addError(fieldName, generalRules.same(value, values.password)),
        addError(fieldName, generalRules.shortFieldMaxLength(value)),
        addError(fieldName, generalRules.required(value)),
      )(errors);

    default:
      return errors;
  }
};

const increaseBidAllowanceValidator = values => (errors, fieldName) => {
  const value = values[fieldName];

  switch (fieldName) {
    case 'requestedBidAllowanceIncrease':
      return compose(
        addError(fieldName, validateFormattedCurrency(value, {
          required: true,
          moreThan: 0,
        })),
      )(errors);
    default:
      return errors;
  }
};

export const signInValidate = values => ['email', 'password'].reduce(signInValidator(values), {});

export const forgotPasswordValidate = values => ['email'].reduce(signInValidator(values), {});

export const bankInformationValidate = values => ['bankName', 'bankerName', 'bankerEmail', 'bankerPhoneNumber', 'bankerAddress', 'notes', 'requestedBidAllowance'].reduce(
  bankInformationValidator(values),
  {},
);

export const updateAccountValidate = values => ['firstName', 'lastName', 'company', 'department', 'jobTitle', 'phoneNumber', 'email'].reduce(
  updateAccountValidator(values),
  {},
);

export const contactUsValidate = values => ['name', 'email', 'phoneNumber', 'subject', 'buyerAddress', 'text'].reduce(contactUsValidator(values), {});

export const settingsValidate = values => ['oldPassword', 'password', 'confirmPassword'].reduce(settingsValidator(values), {});

export const changePasswordValidate = values => ['password', 'confirmPassword'].reduce(changePasswordValidator(values), {});

export const increaseBidAllowanceValidate = values => [
  'requestedBidAllowanceIncrease',
].reduce(increaseBidAllowanceValidator(values), {});
