import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

// styles
import styles from './styles';

const Faq = ({ classes }) => (
  <Grid container justify="center">
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.heading}
    >
      <span>Frequently Asked Questions</span>
    </Grid>

    <Grid
      container
      item
      xs={12}
      sm={12}
      md={8}
      lg={8}
      justify="left"
      className={classes.textContainer}
    >
      <span className={classes.sectionHeading}>
        <b>General</b>
      </span>
      <p className={classes.text}>
        <b>What is NOW?</b>
        <br />
        <br />
        NonOpWells, Inc. (“NOW”) is digital a platform to buy and sell
        non-operated working interests in oil and gas wellbores. The platform
        specializes in transactions centered around an AFE, well proposal, or
        compulsory pooling election.
      </p>

      <p className={classes.text}>
        <b>
          {' '}
          {'What’s an AFE?'}
        </b>
        <br />
        <br />
        An “AFE” or Authority For Expenditure is usually a formal document that
        an Exploration and Production (“E&P”) company generates to summarize the
        final cost estimates for any type of capital project, including but not
        limited to operations such as drilling or completions. These costs are
        often summarized in various regulatory notices when owners of oil and
        gas assets are undergoing a compulsory pooling process (often called
        “force pooling”) in various states.
      </p>

      <span className={classes.sectionHeading}>
        <b>Onboarding & Registration</b>
      </span>
      <p className={classes.text}>
        <b> What is the registration process?</b>
        <br />
        <br />
        Qualified participants may register automatically
        {' '}
        <Link to="/?modal=register">online</Link>
        , or by emailing
        contact@nonopwells.com. Each registering party will need to appoint an
        account manager to register on behalf of your company. The account
        manager must be a duly authorized signer permitted to sign and transact
        on behalf of your company. Our onboarding wizard will guide you through
        each step in the KYC (“Know Your Customer”) process.
      </p>
      <p className={classes.text}>
        <b> What is KYC? </b>
        <br />
        <br />
        KYC or Know Your Customer is a mandatory set of standards and
        requirements used by broker dealers and other financial institutions to
        verify customers during the registration process. Pease be prepared to
        enter verification details for your business, officers, beneficial
        owners, and authorized signers.
      </p>

      <p className={classes.text}>
        <b>Who is eligible to participate? </b>
        <br />
        <br />
        Our platform is limited to US-domiciled institutions and legal entities.
        Most participants will be E&P companies, energy investment funds, energy
        private equity, or other legal entities which currently hold or have the
        ability to acquire working interests. It is critical that authorized
        users be sophisticated, knowledgeable, and actively participating or
        investing in the exploration and production of oil & gas interests. Due
        to the significant risks inherent in acquiring working interests in oil
        and gas wellbores, and the complex nature of the transactions, no retail
        investors are permitted.
      </p>
      <span className={classes.sectionHeading}>
        <b>Process & Format</b>
      </span>
      <p className={classes.text}>
        <b>What is the sales format?</b>
        <br />
        <br />
        All listings are marketed sealed-bid sales format. Sellers can elect to
        conduct a Cash, Carry, or ORRI Transaction using their own set of
        purchase and sale agreements. Unless otherwise directed by a seller,
        each sales process is meant to be a ‘best and final’ approach with all
        bids being binding once entered.
      </p>

      <p className={classes.text}>
        <b>
          What is the difference between a Cash, Carry, or ORRI Transaction?
        </b>
        <br />
        <br />
        Cash Transactions are when the only form of consideration is cash (US
        Dollars). Carry Transactions occur when a seller retains a portion of
        its interest for purposes of a carry or promote on its interest as the
        form of consideration. ORRI Transactions are a combination of cash and
        the retention of an overriding royalty interests as the combined form of
        consideration.
      </p>

      <p className={classes.text}>
        <b>How long will it take for a listing to go-live on the platform?</b>
        <br />
        <br />
        If the seller has all relevant property details, a listing should take
        between 5-10 minutes to create. The listing will be reviewed for
        completeness and approval will come in less than 24 hours. Under certain
        circumstances when time is of the essence, a seller can contact us to
        request expedited/priority approval.
      </p>

      <p className={classes.text}>
        <b>Are bids binding?</b>
        <br />
        <br />
        All bids are binding and once submitted can only rescinded in writing if
        such notice is remitted prior to acceptance by a Seller.
      </p>

      <p className={classes.text}>
        <b>What diligence information will be available for review?</b>
        <br />
        <br />
        {`A seller must provide basic information about each wellbore
        including location, operator, landing zones/TVD, working interest, net
        revenue interest, and cost estimates, along with a draft copy of the
        closing documents which the winning bidder will be executing upon close.
        It is up to the seller’s discretion what, if any, additional diligence
        documents will be available for bidders to review prior to making a bid.`}
      </p>

      <p className={classes.text}>
        <b>Will title information be available to review prior to bidding?</b>
        <br />
        <br />
        Sellers are encouraged to provide as much relevant information as
        possible for buyers to review in each listing. However, each seller has
        full discretion regarding what diligence documents will be available.
      </p>

      <p className={classes.text}>
        <b>What is the closing process?</b>
        <br />
        <br />
        Sellers have full discretion on who to transact with, regardless of
        highest bid. The closing process will be transaction specific and will
        be determined by the Seller’s transaction documents. For less complex
        transactions, closing typically occurs within 48 hours of a winning
        buyer being notified of a successful bid.
        <br />
        <br />
        Closings are be facilitated by NOW but occur directly between sellers
        and buyers. NOW does not take custody or act as a clearinghouse for any
        cash consideration, nor does NOW take possession of any assignments,
        deeds, or other conveyance forms. Buyers will fund any form of cash
        consideration directly to the seller, and the seller will convey title
        to the properties directly to the buyer.
      </p>

      <p className={classes.text}>
        <b>How do you ensure buyers are able to fund transactions?</b>
        <br />
        <br />
        NOW cannot guarantee that buyers will always fund, but that risk is
        mitigated through the financial verification process. NOW confirms the
        funding of each buyer to as part of the KYC registration process to
        mitigate any execution risk. Each buyer must submit relevant funding
        source details and must allow NOW to contact its bank or financial
        sponsor to confirm the requested bid allowance and supporting
        documentation. The NOW platform tracks a buyer’s outstanding bids and
        potential liabilities to ensure no buyer can bid more than available
        liquidity. Buyers are always free to request a bid limit increase.
      </p>

      <span className={classes.sectionHeading}>
        <b>Fees & Commissions</b>
      </span>

      <p className={classes.text}>
        <b>What is the fee structure?</b>
        <br />
        <br />
        There are no fees or commissions charged to the seller. The winning
        bidder on a listing will pay the lesser of 10% of the purchase price or
        3% of the net AFE value. This commission is subject to change but will
        never be more than any commission outlined in the buyers agreement.
      </p>

      <p className={classes.text}>
        <b>Additional Questions</b>
        <br />
        <br />
        If you have additional questions, please reach out to us at
        contact@nonopwells.com, 832-900-3055, or through our
        {' '}
        <a href="/contact-us">contact us</a>
        {' '}
        portal.
      </p>
    </Grid>
  </Grid>
);

Faq.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(Faq);
