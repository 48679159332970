/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateHistoricalListingTable,
  updateNewMexicoListingTable,
} from 'store/actions/mapActions';

function isDate(dateString) {
  const regex = /^\d{1,2}\/\d{1,2}\/\d{2,4}$|^\d{4}-\d{1,2}-\d{1,2}$/;
  return regex.test(dateString);
}

function parseDate(dateString) {
  if (dateString.includes('/')) {
    const [month, day, year] = dateString.split('/');
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');
    const paddedYear = year.length === 2 ? `20${year}` : year;
    return new Date(`${paddedMonth}/${paddedDay}/${paddedYear}`);
  }
  if (dateString.includes('-')) {
    const [year, month, day] = dateString.split('-');
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');
    return new Date(`${year}-${paddedMonth}-${paddedDay}`);
  }
}

export const MapDataGrid = () => {
  const dispatch = useDispatch();
  const historicalListings = useSelector(
    state => state.map.currentHistoricalData,
  );
  const newMexicoListings = useSelector(
    state => state.map.currentNewMexicoData,
  );
  const columns = React.useMemo(() => {
    const columnsKey = [
      {
        field: 'historicalId',
        headerName: 'Historical ID',
        width: 200,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'basin',
        headerName: 'Basin',
        width: 150,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'county',
        headerName: 'County',
        width: 150,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'grantorRaw',
        headerName: 'Grantor Raw',
        width: 180,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'grantorParent',
        headerName: 'Grantor Parent',
        width: 180,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'granterSignatory',
        headerName: 'Granter Signatory',
        width: 220,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'grantee',
        headerName: 'Grantee',
        width: 180,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'granteeParent',
        headerName: 'Grantee Parent',
        width: 180,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'granteeSignatory',
        headerName: 'Grantee Signatory',
        width: 220,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'recorded',
        headerName: 'Recorded Date',
        width: 220,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'effective',
        headerName: 'Effective Date',
        width: 175,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'letterAgreement',
        headerName: 'Letter Agreement',
        width: 200,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'transactionMonth',
        headerName: 'Transaction Month',
        width: 200,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'originalOperator',
        headerName: 'Original Operator',
        width: 220,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'currentOperator',
        headerName: 'Current Operator',
        width: 220,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'api',
        headerName: 'API',
        width: 100,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'wellName',
        headerName: 'Well Name',
        width: 150,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'interval',
        headerName: 'Interval',
        width: 130,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 170,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'spudDate',
        headerName: 'Spud Date',
        width: 170,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'completion',
        headerName: 'Completion Date',
        width: 200,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'prodMonth',
        headerName: 'Production Month',
        width: 200,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'oilSpot',
        headerName: 'Oil Spot Price',
        width: 175,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'oilFourMonthFwd',
        headerName: 'Oil 4-Month Forward',
        width: 200,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'gasSpot',
        headerName: 'Gas Spot Price',
        width: 180,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'cashCarry',
        headerName: 'Cash Carry',
        width: 150,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'assignedPortion',
        headerName: 'Assigned Portion',
        width: 200,
      },
      {
        field: 'carryPercent',
        headerName: 'Carry Percent',
        width: 175,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'WIPercent',
        headerName: 'WI Percent',
        width: 150,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'NRIPercent',
        headerName: 'NRI Percent',
        width: 170,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'notes',
        headerName: 'Notes',
        width: 300,
        type: 'singleSelect',
        valueOptions: [],
      },
    ];

    historicalListings.forEach(item => {
      Object.keys(item).forEach(key => {
        const elementIndex = columnsKey.findIndex(el => el.field === key);
        if (
          elementIndex !== -1
          && columnsKey[elementIndex].valueOptions
          && !columnsKey[elementIndex].valueOptions.includes(item[key])
        ) {
          columnsKey[elementIndex].valueOptions.push(item[key]);
        }
      });
    });

    const sortedArray = columnsKey.map(obj => {
      if (Array.isArray(obj.valueOptions)) {
        const sortedOptions = obj.valueOptions.slice().sort((a, b) => {
          if (typeof a === 'string' && typeof b === 'string') {
            if (isDate(a) && isDate(b)) {
              const dateA = parseDate(a);
              const dateB = parseDate(b);
              return dateB - dateA;
            }
            return a.localeCompare(b);
          }
          return 0;
        });

        return {
          ...obj,
          valueOptions: sortedOptions,
        };
      }
      return obj;
    });

    return sortedArray;
  }, [historicalListings]);

  const columnsNewMexico = React.useMemo(() => {
    const columnsKey = [
      {
        field: 'api',
        headerName: 'API',
        width: 200,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'wellName',
        headerName: 'Well Name',
        width: 150,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'wellFamily',
        headerName: 'Well Family',
        width: 150,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'receivedDate',
        headerName: 'Received Date',
        width: 180,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'approvalDate',
        headerName: 'Approval Date',
        width: 180,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'county',
        headerName: 'County',
        width: 220,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'state',
        headerName: 'State',
        width: 180,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'landingZone',
        headerName: 'Landing Zone',
        width: 180,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'pollingCode',
        headerName: 'Polling Code',
        width: 220,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'surfaceLat',
        headerName: 'Surface Lat',
        width: 220,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'surfaceLong',
        headerName: 'Surface Long',
        width: 175,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'bottomLat',
        headerName: 'Bottom Lat',
        width: 200,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'bottomLong',
        headerName: 'Bottom Long',
        width: 200,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'WIPercent',
        headerName: 'WI Percent',
        width: 220,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'operator',
        headerName: 'Current Operator',
        width: 220,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'case',
        headerName: 'Case',
        width: 150,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'tvd',
        headerName: 'TVD',
        width: 150,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'afe',
        headerName: 'AFE',
        width: 130,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'owner',
        headerName: 'Owner',
        width: 170,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'notes',
        headerName: 'Notes',
        width: 170,
        type: 'singleSelect',
        valueOptions: [],
      },
      {
        field: 'document',
        headerName: 'Document',
        width: 200,
        renderCell: params => {
          const handleDownload = () => {
            const url = params.value;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'document.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          };

          if (!params.value) {
            return null;
          }

          return (
            <button
              type="button"
              onClick={handleDownload}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: 'blue',
                textDecoration: 'underline',
              }}
            >
              Download
            </button>
          );
        },
      },
    ];

    newMexicoListings.forEach(item => {
      Object.keys(item).forEach(key => {
        const elementIndex = columnsKey.findIndex(el => el.field === key);
        if (
          elementIndex !== -1
          && columnsKey[elementIndex].valueOptions
          && !columnsKey[elementIndex].valueOptions.includes(item[key])
        ) {
          columnsKey[elementIndex].valueOptions.push(item[key]);
        }
      });
    });

    const sortedArray = columnsKey.map(obj => {
      if (Array.isArray(obj.valueOptions)) {
        const sortedOptions = obj.valueOptions.slice().sort((a, b) => {
          if (typeof a === 'string' && typeof b === 'string') {
            if (isDate(a) && isDate(b)) {
              const dateA = parseDate(a);
              const dateB = parseDate(b);
              return dateB - dateA;
            }
            return a.localeCompare(b);
          }
          return 0;
        });

        return {
          ...obj,
          valueOptions: sortedOptions,
        };
      }
      return obj;
    });

    return sortedArray;
  }, [newMexicoListings]);

  const useStyles = makeStyles({
    root: {
      '& .MuiDataGrid-cell': {
        fontSize: '12px',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: '12px',
      },
      '& .MuiDataGrid-toolbarContainer': {
        gap: '15px',
      },
    },
  });

  const [tab, setTab] = React.useState('historical');

  const customClasses = useStyles();

  const handleHistoricalListingTableChange = params => {
    const visibleIds = params?.visibleRows?.visibleRows || [];
    const sortedCurrentState = visibleIds.slice().sort((a, b) => a - b);
    const sortedCurrentListings = historicalListings
      .slice()
      .sort((a, b) => a.id - b.id);

    if (
      sortedCurrentListings.every(
        (item, index) => Number(item.id) === Number(sortedCurrentState[index]),
      )
    ) {
      return;
    }

    if (visibleIds.length === 0) {
      dispatch(updateHistoricalListingTable(historicalListings));
      return;
    }

    const visibleData = historicalListings.filter(item => visibleIds.includes(item.id.toString()));
    dispatch(updateHistoricalListingTable(visibleData));
  };

  const handleNewMexicoListingTableChange = params => {
    const visibleIds = params?.visibleRows?.visibleRows || [];

    const sortedCurrentState = visibleIds.slice().sort((a, b) => a - b);
    const sortedCurrentListings = newMexicoListings
      .slice()
      .sort((a, b) => a.id - b.id);

    if (
      sortedCurrentListings.every(
        (item, index) => Number(item.id) === Number(sortedCurrentState[index]),
      )
    ) {
      return;
    }

    if (visibleIds.length === 0) {
      dispatch(updateNewMexicoListingTable(newMexicoListings));
      return;
    }

    const visibleData = newMexicoListings.filter(item => visibleIds.includes(item.id.toString()));
    dispatch(updateNewMexicoListingTable(visibleData));
  };

  function CustomToolbar() {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <GridToolbar />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: '-37px',
          background: '#fff',
          zIndex: '999',
          display: 'flex',
        }}
      >
        <h2
          style={{
            width: 'fit-content',
            cursor: 'pointer',
            padding: '4px 10px',
            border: 'solid 1px #dadada',
            borderBottom: 'none',
            borderRight: 'none',
            fontSize: '20px',
            ...(tab === 'historical' && { color: 'rgb(63, 81, 181)' }),
          }}
          onClick={() => setTab('historical')}
        >
          Historical Transactions
        </h2>
        <h2
          style={{
            width: 'fit-content',
            cursor: 'pointer',
            padding: '4px 10px',
            border: 'solid 1px #dadada',
            borderBottom: 'none',
            fontSize: '20px',
            ...(tab === 'newMexico' && { color: 'rgb(63, 81, 181)' }),
          }}
          onClick={() => setTab('newMexico')}
        >
          New Mexico Pooling
        </h2>
      </div>
      <>
        <DataGridPro
          style={{
            padding: '10px',
            ...(tab !== 'historical' && { display: 'none' }),
          }}
          rows={historicalListings}
          columns={tab === 'historical' ? columns : columnsNewMexico}
          disableSelectionOnClick
          className={customClasses.root}
          rowsPerPageOptions={[]}
          onStateChange={handleHistoricalListingTableChange}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
        <DataGridPro
          style={{
            padding: '10px',
            ...(tab === 'historical' && { display: 'none' }),
          }}
          rows={newMexicoListings}
          columns={columnsNewMexico}
          disableSelectionOnClick
          className={customClasses.root}
          rowsPerPageOptions={[]}
          onStateChange={handleNewMexicoListingTableChange}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </>
    </>
  );
};
