import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import TeamBioDropdown from './components/teamBioDropdown';
import { teamMemberNames, teamMemberBio } from './constants';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

// assets
import headshotJames from '../../../../assets/images/headshot-james.png';
import headshotRuss from '../../../../assets/images/headshot-russ.png';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const TeamBioSection = ({ classes }) => {
  useEffect(() => {
    configureAnchors({ scrollDuration: 800 });
    // eslint-disable-next-line
  }, []);

  return (
    <ScrollableAnchor id="team_bio">
      <Grid container direction="column" className={classes.wrapper}>
        <Grid className={classes.headerContainer}>
          <div className={classes.headerText}>
            TEAM
          </div>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="space-between"
          className={classes.bioContainer}
        >
          <Grid container item className={classes.memberBio}>
            <TeamBioDropdown
              className={classes.memberBioText}
              memberName={teamMemberNames.james}
              memberBio={teamMemberBio.jamesBio}
              photoSource={headshotJames}
            />
          </Grid>
          <Grid container item className={classes.memberBio}>
            <TeamBioDropdown
              className={classes.memberBioText}
              memberName={teamMemberNames.russell}
              memberBio={teamMemberBio.russellBio}
              photoSource={headshotRuss}
            />
          </Grid>
        </Grid>
      </Grid>
    </ScrollableAnchor>
  );
};

TeamBioSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(TeamBioSection);
