import styles from '../ImpliedClosingFees/styles';

export default {
  container: {
    display: 'flex',
    border: 'solid 1px #E5E5E5',
    padding: '10px 20px',
    gap: '25px',
    minWidth: '400px',
  },
  header: styles.header,
  description: {
    ...styles.descriptionContainer,
    fontWeight: '400',
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    color: '#000000',
    opacity: '0.5',
    fontSize: '18px',
    fontWeight: 'bold',
  },
};
