import {
  all, fork, take, takeLatest, call, put, select,
} from 'redux-saga/effects';
import queryString from 'query-string';

// actions
import {
  getProperties,
  getStates,
  getBasins,
  getCounties,
  getSellerStatuses,
  getListingsForAuctionsActive,
  getListingsForAuctionsEnded,
  getListingsForSellerActionNeeded,
  getListingsSold,
} from '../actions/myPropertiesActions';

// api methods
import Api from 'api/properties';

// storage helpers functions
import * as storage from 'now-frontend-shared/utils/storage';

import { strIf } from 'now-shared/helpers/text-helpers';
import { SortDirection } from 'now-shared/enums/sort-direction';

function* ensureGetStates() {
  try {
    const { data } = yield call(Api.getStates);
    yield put({ type: getStates.success, payload: data });
  } catch (err) {
    yield put({ type: getStates.failure, err });
  }
}

function* watchGetState() {
  yield takeLatest(getStates.type, ensureGetStates);
  yield take(getStates.success);
}

function* ensureGetBasins() {
  const stateId = yield select(({ myProperties }) => myProperties.stateId);
  try {
    const { data } = yield call(Api.getBasins, { url: `/states/${stateId}/basins` });
    yield put({ type: getBasins.success, payload: data });
  } catch (err) {
    yield put({ type: getBasins.failure, err });
  }
}

function* watchGetBasins() {
  yield takeLatest(getBasins.type, ensureGetBasins);
  yield take(getBasins.success);
}

function* ensureGetCounties() {
  const stateId = yield select(({ myProperties }) => myProperties.stateId);
  const basinId = yield select(({ myProperties }) => myProperties.basinId);
  try {
    const { data } = yield call(Api.getCounties, { url: `/states/${stateId}/basins/${basinId}` });
    yield put({ type: getCounties.success, payload: data });
  } catch (err) {
    yield put({ type: getCounties.failure, err });
  }
}

function* watchGetCounties() {
  yield takeLatest(getCounties.type, ensureGetCounties);
  yield take(getCounties.success);
}

function* ensureGetProperties({ payload: query }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const baseUrl = `/properties${query}&type=seller`;
    const { data } = yield call(Api.getProperties, {
      url: query.includes('order')
        ? baseUrl
        : `${baseUrl}&limit=10&order=endTime&sort=${SortDirection.Descending}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    yield put({ type: getProperties.success, payload: data });
  } catch (err) {
    yield put({ type: getProperties.failure, err });
  }
}

function* watchGetProperties() {
  yield takeLatest(getProperties.type, ensureGetProperties);
  yield take(getProperties.success);
}

function* ensureGetListingsForAuctionsActive({ payload: params }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const query = queryString.stringify({
      ...params,
    }, { arrayFormat: 'bracket' });
    const sellerId = yield select(({ auth }) => auth.user.id);
    const baseUrl = `/sellers/${sellerId}/properties${strIf(`?${query}`, !!query)}`;
    const { data } = yield call(Api.getListingsForSeller, {
      url: baseUrl,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    yield put({ type: getListingsForAuctionsActive.success, payload: data });
  } catch (err) {
    yield put({ type: getListingsForAuctionsActive.failure, err });
  }
}

function* watchGetListingsForAuctionsActive() {
  yield takeLatest(getListingsForAuctionsActive.type, ensureGetListingsForAuctionsActive);
  yield take(getListingsForAuctionsActive.success);
}

function* ensureGetListingsForAuctionsEnded({ payload: params }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const query = queryString.stringify({
      ...params,
    }, { arrayFormat: 'bracket' });
    const sellerId = yield select(({ auth }) => auth.user.id);
    const baseUrl = `/sellers/${sellerId}/properties${strIf(`?${query}`, !!query)}`;
    const { data } = yield call(Api.getListingsForSeller, {
      url: baseUrl,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    yield put({ type: getListingsForAuctionsEnded.success, payload: data });
  } catch (err) {
    yield put({ type: getListingsForAuctionsEnded.failure, err });
  }
}

function* watchGetListingsForAuctionsEnded() {
  yield takeLatest(getListingsForAuctionsEnded.type, ensureGetListingsForAuctionsEnded);
  yield take(getListingsForAuctionsEnded.success);
}

function* ensureGetListingsForSellerActionNeeded({ payload: params }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const query = queryString.stringify({
      ...params,
      forActionNeeded: true,
    }, { arrayFormat: 'bracket' });
    const sellerId = yield select(({ auth }) => auth.user.id);
    const baseUrl = `/sellers/${sellerId}/properties${strIf(`?${query}`, !!query)}`;
    const { data } = yield call(Api.getListingsForSeller, {
      url: baseUrl,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    yield put({ type: getListingsForSellerActionNeeded.success, payload: data });
  } catch (err) {
    yield put({ type: getListingsForSellerActionNeeded.failure, err });
  }
}

function* watchGetListingsForSellerActionNeeded() {
  yield takeLatest(getListingsForSellerActionNeeded.type, ensureGetListingsForSellerActionNeeded);
  yield take(getListingsForSellerActionNeeded.success);
}

function* ensureGetListingsSold({ payload: params }) {
  try {
    const accessToken = JSON.parse(storage.getStorageItem('accessToken', '{}'));
    const sellerId = yield select(({ auth }) => auth.user.id);
    const query = queryString.stringify({
      ...params,
    }, { arrayFormat: 'bracket' });
    const baseUrl = `/sellers/${sellerId}/properties${strIf(`?${query}`, !!query)}`;
    const { data } = yield call(Api.getListingsForSeller, {
      url: baseUrl,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    yield put({ type: getListingsSold.success, payload: data });
  } catch (err) {
    yield put({ type: getListingsSold.failure, err });
  }
}

function* watchGetListingsSold() {
  yield takeLatest(getListingsSold.type, ensureGetListingsSold);
  yield take(getListingsSold.success);
}

function* ensureGetSellerStatuses() {
  try {
    const { data } = yield call(Api.getStatuses, {
      url: '/properties/statuses?type=seller',
    });
    yield put({ type: getSellerStatuses.success, payload: data });
  } catch (err) {
    yield put({ type: getSellerStatuses.failure, err });
  }
}

function* watchGetSellerStatuses() {
  yield takeLatest(getSellerStatuses.type, ensureGetSellerStatuses);
  yield take(getSellerStatuses.success);
}

export default function* myPropertiesSagas() {
  yield all([
    fork(watchGetListingsSold),
    fork(watchGetProperties),
    fork(watchGetListingsForAuctionsActive),
    fork(watchGetListingsForAuctionsEnded),
    fork(watchGetListingsForSellerActionNeeded),
    fork(watchGetState),
    fork(watchGetBasins),
    fork(watchGetCounties),
    fork(watchGetSellerStatuses),
  ]);
}
