import React, {
  memo,
  useMemo,
  useCallback,
  useState,
  useRef,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// helpers
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import { formatNumberToCurrency } from 'now-shared/helpers/currency-helpers';

// components
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import SelectWinningBidModal from 'components/Modals/SelectWinningBidModal';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
// styles
import styles from './styles';

import { setListingWinner } from 'store/actions/propertyBidsListActions';
import {
  Typography, Collapse, Box, Button, Tooltip,
} from '@material-ui/core';
import WellListingTable from './components/WellListingTable';

const NewBidInfoLayout = ({
  canSelectWinner,
  hasPermissionToSelectWinner,
  classes,
  bid,
  property,
  query,
  ...propsRest
}) => {
  const dispatch = useDispatch();
  const { setModal } = useModalSetter();
  const formatDate = useCallback(
    date => moment(date).format('h:mm A, MMMM D, YYYY'),
    [],
  );
  const bidDate = useMemo(
    () => formatDate(bid.createdAt),
    [formatDate, bid.createdAt],
  );
  const bidType = useMemo(
    () => (bid.carryPercentage ? 'Carry' : 'Cash'),
    [bid],
  );
  const bidId = useMemo(() => bid.id, [bid]);
  const listingId = useMemo(() => bid.property.id, [bid]);
  const bidStatus = useMemo(() => bid.status.title, [bid]);
  const isPropertyArchived = useMemo(() => property.archivedAt, [property]);

  const [isLongCompanyName, setIsLongCompanyName] = useState(false);
  const [requiresTooltip, setRequiresTooltip] = useState(false);
  const companyName = getCompanyName(bid.user.company) || '<missing company>';
  const companyNameRef = useRef();

  useLayoutEffect(() => {
    if (companyNameRef.current) {
      const element = companyNameRef.current;
      const style = window.getComputedStyle(element);
      const lineHeight = parseInt(style.lineHeight, 10);
      const height = parseInt(style.height, 10);
      const { scrollWidth } = element;
      const { offsetWidth } = element;
      const longCompanyName = height / lineHeight > 2;

      setIsLongCompanyName(longCompanyName);

      // Check if text overflows horizontally
      if (longCompanyName && scrollWidth >= offsetWidth) {
        setRequiresTooltip(true);
      }
    }
  }, [companyName]);

  const onHandleSetWinner = useCallback(() => {
    const selectWinner = async closingMethodId => {
      await new Promise((resolve, reject) => dispatch(
        setListingWinner({
          listingId,
          selectedWinnerBidId: bidId,
          closingMethodId,
          query,
          resolve,
          reject,
        }),
      ));
      setModal(null);
    };

    setModal(<SelectWinningBidModal bid={bid} onSuccess={selectWinner} />);
  }, [dispatch, listingId, bid, bidId, setModal, query]);

  const [expanded, toggleExpand] = useState(true);

  const handleExpandClick = () => {
    toggleExpand(!expanded);
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.cardWrapper}>
        <Box className={classes.headerLeft}>
          <Box
            className={classes.status}
            style={{ background: bidType === 'Cash' ? '#87CC9B' : '#B29E78' }}
          >
            {bidType}
          </Box>
          <Box className={classes.headerLeftMain}>
            <Box className={classes.companyNameContainer}>
              <Tooltip
                title={requiresTooltip ? companyName : ''}
                placement="top"
              >
                <Typography
                  ref={companyNameRef}
                  className={
                    isLongCompanyName
                      ? classes.longCompanyNameText
                      : classes.companyNameText
                  }
                >
                  {companyName}
                </Typography>
              </Tooltip>
              <Box
                style={{ display: 'flex', height: 30, alignItems: 'center' }}
              >
                {(bidStatus === 'winner selected'
                  || bidStatus === 'winner') && (
                  <Button
                    variant="outlined"
                    disabled
                    className={classes.winnerButton}
                    style={{
                      borderColor: 'green',
                      color: 'green',
                      marginRight: 5,
                    }}
                    {...(propsRest['data-cy'] && {
                      'data-cy': `winnerButton-${propsRest['data-cy']}`,
                    })}
                  >
                    Winner
                  </Button>
                )}
                <Typography style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                  {bidDate}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.headerRight}>
          <Box display="flex" className={classes.values}>
            <Box style={{ marginRight: 20 }}>
              <Typography style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                {bidType === 'Cash' ? 'Cash Bid:' : 'Carry Bid:'}
              </Typography>
              <Typography variant="h6">
                {bidType === 'Cash'
                  ? `${formatNumberToCurrency(bid.amount)}`
                  : null}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.headerActions}>
            {canSelectWinner
              && bidStatus === 'closed'
              && !isPropertyArchived && (
                <Button
                  onClick={onHandleSetWinner}
                  variant="outlined"
                  style={{
                    borderColor: 'green',
                    color: 'green',
                    marginRight: 10,
                  }}
                  disabled={!hasPermissionToSelectWinner}
                  {...(propsRest['data-cy'] && {
                    'data-cy': `winnerButton-${propsRest['data-cy']}`,
                  })}
                >
                  Select Winner
                </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Box style={{ width: '100%', display: 'flex' }}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box style={{ display: 'flex', width: '100%' }}>
            <Box style={{ maxWidth: '100%' }}>
              <WellListingTable
                classes={classes}
                property={property}
                bid={bid}
              />
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

NewBidInfoLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  canSelectWinner: PropTypes.bool,
  hasPermissionToSelectWinner: PropTypes.bool,
  setCurrentWells: PropTypes.func,
  'data-cy': PropTypes.string,
};

NewBidInfoLayout.defaultProps = {
  hasPermissionToSelectWinner: true,
  'data-cy': undefined,
};

export default compose(withStyles(styles), memo)(NewBidInfoLayout);
