export default {
  card: {
    display: 'grid',
    columnGap: '20px',
    rowGap: '4px',
    gridTemplateRows: '1fr 20px',
    placeItems: 'center',
    borderRadius: '4px 4px 4px 4px',
    boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2), 
    0px 4px 5px rgba(0, 0, 0, 0.14), 
    0px 1px 10px rgba(0, 0, 0, 0.12)`,
    margin: '10px 0px 10px 0px',
    transition: 'all 0.05s linear',

    '&:hover': {
      borderRadius: '4px 4px 4px 4px',
      boxShadow: `0px 4px 4px 4px rgba(0, 0, 0, 0.1), 
      0px 8px 5px 4px rgba(0, 0, 0, 0.07), 
      0px 2px 10px 4px rgba(0, 0, 0, 0.06)`,
      margin: '10px 0px 10px 0px',
      transition: 'all 0.05s linear',
      transform: 'scale(1.007, 1.007)',
    },
  },

  status: {
    textTransform: 'uppercase',
    color: '#ffffff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    letterSpacing: '0.4px',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    transform: 'rotate3d(0, 0, 1, 180deg)',
    gridRow: '1 / 3',
    overflowWrap: 'anywhere',
    width: '61px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },

  active: {
    background: '#4B6377',
  },

  closed: {
    background: '#E0E5EB',
    color: '#000000',
  },

  topRow: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
  },

  bottomRow: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
  },

  bottomRowText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
    gridRow: '2 / 3',
    gridColumn: '2 / 10',
    width: '100%',
  },

  titleLink: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationThickness: '1px',
    },
  },
};
