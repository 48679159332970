import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import {
  getHistoricalListings,
  getPropertiesMarkers,
  getNewMexicoListings,
  getNewMexicoListingDocuments,
} from '../actions/mapActions';

// api methods
import Api from 'api/map';

function* ensureGetActivePropertiesCoordinates() {
  try {
    const { data } = yield call(Api.getActivePropertiesCoordinates);
    yield put({ type: getPropertiesMarkers.success, payload: data });
  } catch (err) {
    yield put({ type: getPropertiesMarkers.failure, err });
  }
}

function* watchGetActivePropertiesCoordinates() {
  yield takeLatest(
    getPropertiesMarkers.type,
    ensureGetActivePropertiesCoordinates,
  );
  yield take(getPropertiesMarkers.success);
}

function* ensureGetHistoricalListings() {
  try {
    const { data } = yield call(Api.getHistoricalListings);
    yield put({ type: getHistoricalListings.success, payload: data });
  } catch (err) {
    yield put({ type: getHistoricalListings.failure, err });
  }
}

function* watchGetHistoricalListings() {
  yield takeLatest(getHistoricalListings.type, ensureGetHistoricalListings);
  yield take(getHistoricalListings.success);
}

function* ensureGetNewMexicoListings() {
  try {
    const { data } = yield call(Api.getNewMexicoListings);
    const documentData = yield call(Api.getNewMexicoListingDocuments);
    const updatedData = data.map(listing => {
      const document = documentData.data.find(
        doc => doc.case === listing.case,
      );
      return { ...listing, document: document?.url || '' };
    });
    yield put({ type: getNewMexicoListings.success, payload: updatedData });
  } catch (err) {
    yield put({ type: getNewMexicoListings.failure, err });
  }
}

function* ensureGetNewMexicoListingDocuments() {
  try {
    const { data } = yield call(Api.getNewMexicoListingDocuments);
    yield put({ type: getNewMexicoListingDocuments.success, payload: data });
  } catch (err) {
    yield put({ type: getNewMexicoListingDocuments.failure, err });
  }
}

function* watchGetNewMexicoListings() {
  yield takeLatest(getNewMexicoListings.type, ensureGetNewMexicoListings);
  yield take(getNewMexicoListings.success);
}

function* watchGetNewMexicoListingDocuments() {
  yield takeLatest(
    getNewMexicoListingDocuments.type,
    ensureGetNewMexicoListingDocuments,
  );
  yield take(getNewMexicoListings.success);
}

export default function* mapViewSagas() {
  yield all([
    fork(watchGetActivePropertiesCoordinates),
    fork(watchGetNewMexicoListings),
    fork(watchGetNewMexicoListingDocuments),
    fork(watchGetHistoricalListings),
  ]);
}
