import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import { ReactComponent as UnionWhite } from '../../../../assets/shape/Union-white.svg';
import { ReactComponent as UnionGrey } from '../../../../assets/shape/Union-grey.svg';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Button from 'now-frontend-shared/components/Button';

// modal
import SignupModal from 'components/Modals/SignupModal';

// styles
import styles from './styles';

const OverviewSection = ({ classes }) => {
  const { setModal } = useModalSetter();
  useEffect(() => {
    configureAnchors({ scrollDuration: 800 });
    // eslint-disable-next-line
  }, []);

  const handleOpenSignUpPage = () => {
    setModal(<SignupModal />);
  };

  return (
    <ScrollableAnchor id="overview_section">
      <Grid container direction="column" className={classes.wrapper}>
        <Grid container item className={classes.unionContainer}>
          <Grid container item className={classes.whiteContainer}>
            <UnionWhite className={classes.unionWhite} />
            <div className={classes.unionWhiteText}>
              <div className={classes.unionWhiteHeader}>OVERVIEW</div>
              <div className={classes.unionWhiteDescription}>
                NonOpWells, Inc. (“NOW”) is a sealed-bid A&D service for the
                purchase and sale of non-operated oil and gas assets. The NOW
                team understands the complexity of non-op assets, and leverages
                industry experience with technology to provide a straightforward
                sales process.
              </div>
            </div>
            <Grid
              item
              className={classes.buttonContainer}
              alignItems="flex-start"
            >
              <Grid item className={classes.button}>
                <Button
                  label="REGISTER NOW"
                  buttonColor="primaryDarkWithBorder"
                  fullWidth
                  onClick={handleOpenSignUpPage}
                  className={classes.button}
                  data-cy="overviewSectionRegisterNowButton"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item className={classes.greyContainer}>
            <UnionGrey className={classes.unionGrey} />
            <div className={classes.unionGreyText}>
              Non-operated assets provide oil and gas companies and investors
              direct access to wellbore investments that are operated by third
              parties. The NOW platform is niche. It focuses on the non-operated
              market, and specializes in wellbore transactions and the immediate
              need to respond to a well proposal or authority for expenditure
              (“AFE”).
            </div>
          </Grid>
        </Grid>
      </Grid>
    </ScrollableAnchor>
  );
};

OverviewSection.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(OverviewSection);
