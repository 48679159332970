import { asyncAction, action } from 'now-frontend-shared/utils/actions';

// async actions
export const getCurrentProperty = asyncAction('editProperty/GET_CURRENT_PROPERTY');
export const editProperty = asyncAction('editProperty/EDIT_PROPERTY');
export const editDraftProperty = asyncAction('editDraftProperty/EDIT_DRAFT_PROPERTY');
export const getStates = asyncAction('editProperty/GET_STATES');
export const getBasins = asyncAction('editProperty/GET_BASINS');
export const getCounties = asyncAction('editProperty/GET_COUNTIES');
export const getLandingZones = asyncAction('editProperty/GET_LANDING_ZONES');
export const getPreSignedUrls = asyncAction('editProperty/GET_PRE_SIGNED_URLS');

// sync action
export const setBasinId = action('editProperty/SET_BASIN_ID');
export const setStateId = action('editProperty/SET_STATE_ID');
export const setAWSData = action('editProperty/SET_AWS_DATA');
export const removeAWSDataFile = action('editProperty/REMOVE_AWS_DATA_FILE');
export const clearAWSData = action('editProperty/CLEAR_AWS_DATA');
export const setUnloadedFilesExist = action('editProperty/SET_UNLOADED_FILES_EXIST');
export const clearState = action('editProperty/CLEAR_STATE');
