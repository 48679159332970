import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components

// helpers
import { formatNumberToCurrency } from 'now-shared/helpers/currency-helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const TotalListingCost = ({
  classes,
  bid,
  totalCost,
  transactionType,
  closingCost,
}) => {
  const totalCostFormatted = useMemo(
    () => (totalCost === undefined ? '-' : formatNumberToCurrency(totalCost, true)),
    [totalCost],
  );
  const bidFormatted = useMemo(
    () => (bid === undefined ? '-' : formatNumberToCurrency(bid, true)),
    [bid],
  );
  const closingCostFormatted = useMemo(
    () => (closingCost === undefined
      ? '-'
      : formatNumberToCurrency(closingCost, true)),
    [closingCost],
  );

  return (
    <div className={classes.container}>
      <div className={classes.description}>
        <span className={classes.title}>
          {transactionType === 'cash' ? 'Bid' : ' Implied Purchase Price'}
          :
        </span>
        <span>{bidFormatted}</span>
      </div>
      <div className={classes.description}>
        <span className={classes.title}>Closing Fee:</span>
        <span>{closingCostFormatted}</span>
      </div>
      <div className={classes.description}>
        <span className={classes.title}>Total:</span>
        <span>{totalCostFormatted}</span>
      </div>
    </div>
  );
};

TotalListingCost.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  transactionType: PropTypes.string.isRequired,
  totalCost: PropTypes.number,
  closingCost: PropTypes.string.isRequired,
  bid: PropTypes.string.isRequired,
};

TotalListingCost.defaultProps = {
  totalCost: undefined,
};

export default compose(memo, withStyles(styles))(TotalListingCost);
