import jwt from 'jsonwebtoken';

function getAccessToken() {
  return (localStorage.getItem('accessToken') || '').replaceAll(/"/g, '');
}

export function getUserPermissions() {
  const token = getAccessToken();
  try {
    const decoded = jwt.decode(token);
    return decoded.permissions;
  } catch (err) {
    console.error('Token decoding failed:', err);
    return null;
  }
}
