import React, {
  useEffect, useMemo, useState, useCallback, useRef,
} from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useDetectPrint from 'react-detect-print';

import PropTypes from 'prop-types';
import { compose } from 'redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { toast } from 'react-toastify';

// layouts
import BottomSection from 'layouts/AuthSections/BottomSection';
import HeadSection, { returnToPreviousPage } from 'now-frontend-shared/layouts/AuthSections/HeadSection';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';
import DocumentViewLayout, { DocumentLayoutContainer } from 'now-frontend-shared/layouts/DocumentViewLayout';
import SignatureFormLayout from 'now-frontend-shared/layouts/SignatureFormLayout';

// components
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { warnAboutUnsavedForm } from 'now-frontend-shared/hoc/warnAboutUnsavedForm';
import { getAgreementSignatureAttachments } from 'now-frontend-shared/helpers/pdfAttachment';
import { getSellerAgreementAttachmentOffsets } from 'now-frontend-shared/helpers/seller-agreement-pdf';
import IconSaveAlt from '@material-ui/icons/SaveAlt';
import TipTap, { ExtensionName } from 'now-frontend-shared/components/TipTap';
import { WrappedPage } from 'layouts/WrappedRoute';
import Header from 'components/Header';
import Spinner from 'now-frontend-shared/components/Spinner';

// constants
import defaultNonPreFilledAgreementPdf from 'now-shared/assets/docs/seller-agreement.pdf';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import { primaryContrast } from 'now-frontend-shared/themes/colors';
import styles from '../styles';

// store
import { getNonOpWellsSignatureDetails } from 'store/actions/companiesActions';
import { getSellerAgreementTemplate, sendSellerAgreement, updateSellerAgreement } from 'store/actions/authActions';
import { getAllStates } from 'store/actions/statesActions';

// helpers
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import {
  getAgreementName,
  sellerAgreementName,
} from 'now-shared/helpers/company-helpers';
import { downloadFileAsFilename } from 'now-frontend-shared/utils/download-helpers';
import {
  pageInitiallyRenderedClassName,
  wrongVersionErrorClassName,
} from 'now-shared/helpers/webdriver-helpers';
import { apiAuthTokenQueryParamName } from 'now-shared/helpers/auth-helpers';
import { getAccessToken } from 'auth/auth-helpers';
import {
  agreementPageSettings,
  doesAgreementHaveDocument,
  isCustomAgreement,
  isOldNonPreFilledAgreement,
  oldNonPreFilledSellerAgreementVersion,
} from 'now-shared/helpers/agreement-helpers';
import { toDateString, getSignatureDateString } from 'now-shared/helpers/time-helpers';

// validations
import { validateSignatureForm } from 'now-shared/validation/signature-validation';
import { formValidationHasErrors } from 'now-shared/validation/validation-rules';

import { COMPANY_DASHBOARD } from 'constants/registrationFlow';

const formName = 'sellerAgreement';
const agreementName = sellerAgreementName;

// NOTE and TODO [SEE NOW-1721]:
// This frontend page (url '/seller-agreement') is active, but no longer needed.
// This page has been replaced by frontend-main/src/pages/Business/components/SellerCertificateView.
// The endpoint for this page @Post('companies/:companyId/seller-agreement') is also no longer needed.
// Deprecate and remove this page when possible.

const SellerAgreement = ({
  history,
  handleSubmit,
  company,
  states,
  stateId,
  accountManager,
  streetAddress1,
  streetAddress2,
  businessCity,
  businessZip,
  sellerAgreement,
  sellerAgreementTemplate,
  sellerAgreementTemplateIsLoading,
  isSubmitting,
  classes,
  authUserFullName,
  signer2Name,
  signer2Title,
}) => {
  const hasSavedAgreement = !!sellerAgreement;

  const savedAgreementIsMissingDocument = !!sellerAgreement && !doesAgreementHaveDocument(sellerAgreement);

  const isEditable = !hasSavedAgreement;

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => { dispatch(getNonOpWellsSignatureDetails()); }, [dispatch]);

  useEffect(() => {
    dispatch(getAllStates());
  }, [dispatch]);

  const query = queryString.parse(location.search, { arrayFormat: 'bracket' });
  const isPrintView = useDetectPrint() || query.print === null;
  const { version: requiredTemplateVersion } = query;

  const [tipTapContent, setTipTapContent] = useState(undefined);

  const [tipTapIsRendered, setTipTapIsRendered] = useState(false);

  const [renderError, setRenderError] = useState();

  const getAddressState = () => states.filter(state => state.id === stateId);
  const addressState = getAddressState();

  const businessAddressLine1 = streetAddress2 ? `${streetAddress1}, ${streetAddress2}` : `${streetAddress1}`;
  const businessAddressLine2 = `${businessCity}, ${addressState[0]?.title} ${businessZip}`;

  const tipTapData = useMemo(() => ({
    [ExtensionName.Company]: {
      company,
    },
    [ExtensionName.AccountManager]: {
      accountManager,
    },
    [ExtensionName.BusinessAddressLine1]: {
      businessAddressLine1,
    },
    [ExtensionName.BusinessAddressLine2]: {
      businessAddressLine2,
    },
  }), [
    company,
    accountManager,
    businessAddressLine1,
    businessAddressLine2,
  ]);

  const hasAgreementFileStored = !!sellerAgreement?.document;

  const hasCustomAgreement = !!sellerAgreement && isCustomAgreement(sellerAgreement);

  const hasOldNonPreFilledAgreement = !!sellerAgreement && isOldNonPreFilledAgreement(sellerAgreement);

  const isRenderingWithTipTap = !hasSavedAgreement;

  let documentDownloadUrl;
  let documentDisplayedUrl;
  if (hasAgreementFileStored) {
    documentDownloadUrl = sellerAgreement.document.downloadUrl;
  } else if (hasOldNonPreFilledAgreement) {
    documentDownloadUrl = defaultNonPreFilledAgreementPdf;
  } else if (!savedAgreementIsMissingDocument) {
    documentDisplayedUrl = `${process.env.REACT_APP_API_URL}/seller-agreement-pdf`;
    documentDownloadUrl = queryString.stringifyUrl({
      url: documentDisplayedUrl,
      query: {
        // TODO: [SECURITY][FEATURE] find a way to download the file
        // without having to send the auth token in the URL?
        [apiAuthTokenQueryParamName]: getAccessToken(),
      },
    });
  }

  if (!documentDisplayedUrl) {
    documentDisplayedUrl = documentDownloadUrl;
  }

  useEffect(() => {
    if (isRenderingWithTipTap) {
      dispatch(getSellerAgreementTemplate());
    }
  }, [dispatch, isRenderingWithTipTap]);

  useEffect(() => {
    if (sellerAgreementTemplate) {
      if (requiredTemplateVersion && sellerAgreementTemplate.version !== requiredTemplateVersion) {
        toast.error(`Template version (${requiredTemplateVersion}) is not supported`);
        setRenderError(wrongVersionErrorClassName);
      } else {
        setTipTapContent(sellerAgreementTemplate.document);
        setRenderError(undefined);
      }
    }
  }, [sellerAgreementTemplate, requiredTemplateVersion]);

  const sellerAgreementTemplateRef = useRef();
  sellerAgreementTemplateRef.current = sellerAgreementTemplate;

  const tipTapIsRenderedRef = useRef();
  tipTapIsRenderedRef.current = tipTapIsRendered;

  const companyRef = useRef();
  companyRef.current = company;

  const onRendered = useCallback(() => {
    if (
      !tipTapIsRenderedRef.current
      && sellerAgreementTemplateRef.current
      && addressState
    ) {
      setTipTapIsRendered(true);
    }
  }, [addressState]);

  const [signature, setSignature] = useState({
    // TODO: [BUG][INTEGRITY][UX] if anyone besides the original signer is viewing this page, this name and
    // signature below will not be correct.
    name: authUserFullName,
    date: getSignatureDateString(),
    signature: '',
  });

  useEffect(() => {
    setSignature(data => ({
      // TODO: [BUG][INTEGRITY][UX] if anyone besides the original signer is viewing this page, this name and
      // signature below will not be correct.
      ...data,
      date: toDateString(sellerAgreement?.wasSignedOnCreation ? sellerAgreement.createdAt : new Date()),
      // TODO: [BUG] This value should be initialized with the signature value that user did in the registration step,
      // not necessarily the same as the user's current full name.
      signature: sellerAgreement?.wasSignedOnCreation ? authUserFullName : '',
    }));
  }, [sellerAgreement, authUserFullName]);

  const onHandleClose = () => {
    returnToPreviousPage(location, history, COMPANY_DASHBOARD);
  };

  const onHandleSubmit = async () => {
    await new Promise((resolve, reject) => {
      dispatch(
        sellerAgreement
          ? updateSellerAgreement({
            signature: signature.signature,
            resolve,
            reject,
          })
          : sendSellerAgreement({
            signature: signature.signature,
            docVersion: sellerAgreementTemplate.version,
            resolve,
            reject,
          }),
      );
    });
    onHandleClose();
  };

  const signatureChange = e => setSignature({ ...signature, signature: e.target.value });

  const newSignature = signature.signature;

  const printedName = signature.name;

  const signatureDate = signature.date;

  const errors = validateSignatureForm({ signature: newSignature, printedName });

  const attachments = (hasAgreementFileStored || isRenderingWithTipTap)
    ? []
    : getAgreementSignatureAttachments({
      pageNumber: getSellerAgreementAttachmentOffsets.pageNumber,
      offsetLeft: getSellerAgreementAttachmentOffsets.offsetLeft,
      offsetTop: getSellerAgreementAttachmentOffsets.offsetTop,
      signer1Name: signature.signature,
      signer1Title: printedName,
      signer2Name,
      signer2Title,
    });

  const tipTapView = isRenderingWithTipTap
    ? (
      <>
        <Box
          display="flex"
          flexDirection="column"
          width={1}
          style={{
            width: agreementPageSettings.width,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width={1}
            style={{
              paddingTop: isPrintView ? 0 : agreementPageSettings.margin.top,
              paddingBottom: isPrintView ? 0 : agreementPageSettings.margin.bottom,
              paddingLeft: agreementPageSettings.margin.left,
              paddingRight: agreementPageSettings.margin.right,
              boxSizing: 'border-box',
            }}
          >
            <TipTap
              extensionData={tipTapData}
              content={tipTapContent}
              onRendered={onRendered}
            />
          </Box>
        </Box>
        {renderError && (
          <div className={renderError} />
        )}
        {(tipTapIsRendered || renderError) && (
          <div className={pageInitiallyRenderedClassName} />
        )}
      </>
    )
    : null;

  if (isRenderingWithTipTap && sellerAgreementTemplateIsLoading) {
    return (
      <WrappedPage>
        <Spinner wrapped />
      </WrappedPage>
    );
  }

  return (
    (isPrintView && isRenderingWithTipTap)
      ? (
        <>
          {(
            isPrintView
              ? (
                <CssBaseline />
              )
              : (
                <Header />
              )
          )}
          <Box
            style={{
              backgroundColor: primaryContrast,
            }}
          >
            {tipTapView}
          </Box>
        </>
      )
      : (
        <WrappedPage>
          <FormLayout
            onSubmit={handleSubmit(onHandleSubmit)}
          >
            {(
              isSubmitting
              || (sellerAgreementTemplateIsLoading && isRenderingWithTipTap)
            ) && (
              <Spinner backdrop />
            )}
            <HeadSection path={COMPANY_DASHBOARD} />
            {(
              (isRenderingWithTipTap)
                ? (
                  <DocumentLayoutContainer
                    documentName={agreementName}
                  >
                    {tipTapView}
                  </DocumentLayoutContainer>
                )
                : (
                  <DocumentViewLayout
                    document={documentDownloadUrl}
                    documentName={agreementName}
                    attachments={attachments}
                  />
                )
            )}
            {documentDownloadUrl && (
              <div className={classes.downloadContainer}>
                <a
                  onClick={e => {
                    e.preventDefault();
                    downloadFileAsFilename({
                      downloadUrl: documentDownloadUrl,
                      filename: getAgreementName({
                        baseName: agreementName,
                        isCustom: hasCustomAgreement,
                        version: (sellerAgreement && isOldNonPreFilledAgreement(sellerAgreement))
                          ? oldNonPreFilledSellerAgreementVersion
                          : undefined,
                        withExtension: true,
                      }),
                    });
                  }}
                  href={documentDisplayedUrl}
                  className={classes.download}
                  download
                >
                  {agreementName}
                  <IconSaveAlt />
                </a>
              </div>
            )}
            <BottomSection>
              <SignatureFormLayout
                data-cy="sellerAgreementSignature"
                onSignatureChange={signatureChange}
                newSignature={newSignature}
                printedName={printedName}
                signatureDate={signatureDate}
                errors={errors}
                alreadySigned={sellerAgreement?.wasSignedOnCreation}
                hideFields={hasCustomAgreement || savedAgreementIsMissingDocument}
              >
                {isEditable && (
                  <div className={classes.buttonContainer}>
                    <Button
                      className={classes.button}
                      variant="outlined"
                      onClick={() => {
                        onHandleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      data-cy="sellerAgreementSubmitButton"
                      className={classes.button}
                      variant="outlined"
                      type="submit"
                      disabled={
                        formValidationHasErrors(errors)
                        || !signature.date
                        || !signer2Name
                        || !signer2Title
                        || isSubmitting
                        || (isRenderingWithTipTap && sellerAgreementTemplateIsLoading)
                        || savedAgreementIsMissingDocument
                      }
                    >
                      I agree
                    </Button>
                  </div>
                )}
              </SignatureFormLayout>
            </BottomSection>
          </FormLayout>
        </WrappedPage>
      )
  );
};

SellerAgreement.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  company: PropTypes.shape({
    fullLegalCompanyName: PropTypes.string.isRequired,
  }),
  accountManager: PropTypes.string,
  streetAddress1: PropTypes.string,
  streetAddress2: PropTypes.string,
  businessCity: PropTypes.string,
  businessZip: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  states: PropTypes.objectOf(PropTypes.any).isRequired,
  stateId: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  sellerAgreement: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  sellerAgreementTemplate: PropTypes.object,
  sellerAgreementTemplateIsLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  authUserFullName: PropTypes.string.isRequired,
  signer2Name: PropTypes.string.isRequired,
  signer2Title: PropTypes.string.isRequired,
};

SellerAgreement.defaultProps = {
  company: undefined,
  accountManager: undefined,
  streetAddress1: undefined,
  streetAddress2: undefined,
  businessCity: undefined,
  businessZip: undefined,
  stateId: undefined,
  sellerAgreement: undefined,
  sellerAgreementTemplate: undefined,
};

export default compose(
  connect(({ auth, companies, states }) => ({
    initialValues: auth.user.company?.sellerAgreement,
    company: auth.user.company,
    // TODO: [VALIDATION] accoundManager Node should populate with Company Account Manager rather than current User
    accountManager: getUserFullName(auth.user),
    streetAddress1: auth.user.company.businessStreetAddress,
    streetAddress2: auth.user.company.businessStreetAddress2,
    businessCity: auth.user.company.city,
    businessZip: auth.user.company.zipCode,
    states: states.states,
    stateId: auth.user.company.stateId,
    sellerAgreement: auth.user.company?.sellerAgreement,
    sellerAgreementTemplate: auth.sellerAgreementTemplate,
    sellerAgreementTemplateIsLoading: auth.sellerAgreementTemplateIsLoading,
    isSubmitting: auth.isSubmittingAgreement,
    authUserFullName: getUserFullName(auth.user),
    signer2Name: companies.nonOpWellsSignatureDetails?.name || '',
    signer2Title: companies.nonOpWellsSignatureDetails?.title || '',
  })),
  reduxForm({ form: formName }),
  warnAboutUnsavedForm,
  withStyles(styles),
)(SellerAgreement);
