import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import errorIcon from './errorIcon.svg';
import styles from './styles';
import closeIcon from './closeIcon.svg';

function UnfinishedBanner({ classes, pending }) {
  const [showBanner, setShowBanner] = React.useState(true);
  const title = pending ? 'Pending Registration' : 'Unfinished Registration';
  const description = pending
    ? 'Thank you for completing the registration. Your submission is being reviewed by the compliance team. You will receive an email notification once your registration has been approved.'
    : 'Looks like you have not finished your registration. To view all listings you must finish the registration.';

  return showBanner ? (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <img src={errorIcon} className={classes.icon} alt="error" />
        <div
          className={classes.titleContainer}
          data-cy={pending ? 'pendingRegistrationBanner' : 'unfinishedRegistrationBanner'}
        >
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
        </div>
      </div>
      <img src={closeIcon} className={classes.closeIcon} alt="close" onClick={() => setShowBanner(false)} />
    </div>
  ) : null;
}

UnfinishedBanner.defaultProps = {
  pending: false,
};

UnfinishedBanner.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  pending: PropTypes.bool,
};

export default compose(withStyles(styles))(UnfinishedBanner);
