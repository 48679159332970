import { action, asyncAction } from 'now-frontend-shared/utils/actions';

// async actions
export const getPropertiesMarkers = asyncAction('map/GET_PROPERTIES_MARKERS');
export const getHistoricalListings = asyncAction('map/GET_HISTORICAL_LISTINGS');
export const getNewMexicoListings = asyncAction('map/GET_NEW_MEXICO_LISTINGS');
export const getNewMexicoListingDocuments = asyncAction(
  'map/GET_NEW_MEXICO_LISTING_DOCUMENTS',
);

// sync actions
export const clearState = action('map/CLEAR_STATE');
export const resetMap = action('map/RESET_MAP');
export const updateHistoricalData = action('map/UPDATE_HISTORICAL_LISTING_MAP');
export const updateNewMexicoData = action('map/UPDATE_NEW_MEXICO_MAP');
export const updateHistoricalListingTable = action(
  'map/UPDATE_HISTORICAL_LISTING_TABLE',
);
export const updateNewMexicoListingTable = action(
  'map/UPDATE_NEW_MEXICO_LISTING_TABLE',
);
export const updateMapLasso = action('map/UPDATE_NEW_MEXICO_LISTING_TABLE');
