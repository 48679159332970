import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const AboutUs = ({ classes }) => (
  <Grid container justify="center">
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.heading}
    >
      <span>About Us</span>
    </Grid>

    <Grid
      container
      item
      xs={12}
      sm={12}
      md={8}
      lg={8}
      justify="center"
      className={classes.textContainer}
    >
      <span className={classes.text}>
        NonOpWells, Inc. (“NOW”) is a digital marketplace for institutional-only
        participants to buy and sell non-operated oil and gas assets. NOW was
        launched in response to a growing number of non-operated transactions
        that have historically occurred in a decentralized manner, with a goal
        to significantly benefit both buyers and sellers that directly
        participate in oil and gas development.
      </span>

      <span className={classes.text}>
        The NOW marketplace provides an efficient, simple, and standardized
        platform to buy and sell non-operated assets, specifically when capital
        projects are authorized through an Authority for Expenditure (“AFE”) by
        an exploration and production company. The marketplace presents a
        diverse set of direct well-level investment opportunities for
        institutional investors to buy and acquire, while maximizing option
        value for sellers in a confidential and proprietary process.
      </span>
    </Grid>
  </Grid>
);

AboutUs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(AboutUs);
