import { desktopContentMaxWidthOld } from 'layouts/styles';
import { primaryDark, red, white } from '../../themes/colors';
import { secondaryMain } from 'now-frontend-shared/themes/colors';

export default {
  wrapper: {
    flex: 1,
  },
  darkerTile: {
    background: '#F9F9F9',
  },
  totalContainer: {
    margin: '20px 0',
    textAlign: 'right',
    width: '100%',
  },
  container: {
    height: '100%',
  },
  wellsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(13, 1fr)',
  },
  topSection: {
    backgroundColor: primaryDark,
    color: '#fff',
    position: 'sticky',
    top: 0,
    zIndex: '3',
  },
  topSectionContent: {
    maxWidth: desktopContentMaxWidthOld,
    padding: '24px 40px 24px 16px',

    '@media screen and (max-width: 960px)': {
      padding: '20px',
    },
  },
  wellsDesktop: {
    '@media screen and (min-width: 601px)': {
      display: 'none',
    },
  },
  wellsMobile: {
    '@media screen and (max-width: 600px)': {
      display: 'none',
    },
  },
  heading: {
    flex: 1,
    fontSize: '32px',
    lineHeight: '37px',
    fontWeight: 'bold',

    '@media screen and (max-width: 960px)': {
      padding: '12px 0',
      fontSize: '28px',
      lineHeight: '32px',
    },
  },
  wellsCount: {
    color: '#b4b4b4',
  },
  id: {
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  idNumber: {
    fontWeight: 'normal',
  },
  icon: {
    width: '32px',
    height: '32px',
    margin: '2px 28px 0 16px',
    cursor: 'pointer',
    userSelect: 'none',

    '@media screen and (max-width: 960px)': {
      margin: 'unset',
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  topSectionContainer: {
    padding: '16px 0',
    gap: '20px',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '@media screen and (max-width: 960px)': {
      padding: 'unset',
    },
  },
  topSectionHeading: {
    fontSize: '18px',
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'column',

    '@media screen and (max-width: 1280px)': {
      marginRight: '16px',
    },

    '@media screen and (max-width: 960px)': {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '12px 0',
    },
  },
  topHeading: {
    '@media screen and (max-width: 960px)': {
      marginRight: '8px',
    },
  },
  topSectionDescription: {
    marginTop: '8px',
    fontSize: '20px',

    '@media screen and (max-width: 960px)': {
      marginTop: 'unset',
    },
  },
  mapContainer: {
    position: 'relative',
    height: '600px',
  },
  bottomSectionContent: {
    maxWidth: '1500px',
    padding: '42px 20px',
    gap: '20px',

    '@media screen and (max-width: 960px)': {
      padding: '20px',
    },
  },
  bottomContentContainer: {
    padding: '22px 0',

    '@media screen and (max-width: 960px)': {
      padding: '10px 0',
    },
  },
  bottomSectionHeading: {
    padding: '6px',
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#000000',
    borderRight: 'solid 1px #E5E5E5',
    borderBottom: 'solid 1px #E5E5E5',

    '@media screen and (max-width: 960px)': {
      padding: '12px 0',
    },
  },
  bottomSectionDescription: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
  },
  biddingDetailsSectionHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#615656',
  },
  biddingDetailsSectionDescription: {
    marginTop: '8px',
    fontWeight: 'normal',
    fontSize: '14px',
  },
  descriptionSectionHeading: {
    padding: '10px 10px 10px 0',
    fontSize: '17.5px',
    lineHeight: '46px',
    fontWeight: 'bold',
    color: '#615656',
    textAlign: 'center',

    '@media screen and (max-width: 960px)': {
      padding: '12px 0',
    },
  },
  descriptionSectionDescription: {
    marginTop: '8px',
    fontWeight: 'normal',
    textAlign: 'center',
  },
  contentBorders: {
    borderTop: 'solid 1px #E5E5E5',
    borderBottom: 'solid 1px #E5E5E5',
    padding: '8px 0',
  },
  contentHeading: {
    fontSize: '22px',
    fontWeight: '500',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    marginTop: '22px',
  },
  contentSubHeading: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#171721',
    marginTop: 8,
  },
  checkIcon: {
    color: '#9DC284',
    marginLeft: 5,
  },
  redText: {
    color: red,
  },
  sellerBidStatus: {
    fontSize: '20px',
    fontWeight: 'normal',
    color: '#FFFFFF',
    marginTop: '8px',

    '@media screen and (max-width: 960px)': {
      marginTop: 'unset',
      marginLeft: '8px',
    },
  },
  notificationText: {
    color: '#B9B9B9',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    marginBottom: '20px',
    textTransform: 'uppercase',
  },
  listingStatus: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '@media screen and (max-width: 1280px)': {
      marginRight: '16px',
    },

    '@media screen and (max-width: 960px)': {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 0',
    },
  },
  listingStatusHeading: {
    fontSize: '18px',
  },
  separation: {},
  arrow: {
    '@media screen and (max-width: 960px)': {
      width: '100%',
    },
  },
  topInfoContainer: {
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  idContainer: {
    '@media screen and (max-width: 960px)': {
      justifyContent: 'unset',
      padding: '12px 0',
    },
  },
  buttonContainer: {
    display: 'flex',
    gap: '16px',
    '@media screen and (max-width: 960px)': {
      justifyContent: 'unset',
    },
  },
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    fontSize: '22px',
    fontWeight: '500',
    color: '#000000',
    padding: '20px 0 20px 0',
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  documentsContainer: {
    margin: '0px 0px 14px 0px',
    flexDirection: 'row-reverse',
  },
  stepperHorizontal: {
    width: '100%',
  },
  detailsContainer: {
    zIndex: 0,
    backgroundColor: white,
    borderRadius: '4px 4px 4px 4px',
    margin: '0px 0px 14px 0px',
    padding: '14px 14px 14px 14px',
    boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 1px 10px rgba(0, 0, 0, 0.12)`,
    transition: 'all 0.05s linear',
    '&:hover': {
      borderRadius: '4px 4px 4px 4px',
      boxShadow: `0px 4px 4px 4px rgba(0, 0, 0, 0.1),
      0px 8px 5px 4px rgba(0, 0, 0, 0.07),
      0px 2px 10px 4px rgba(0, 0, 0, 0.06)`,
      transition: 'all 0.05s linear',
      transform: 'scale(1.007, 1.007)',
      position: 'relative',
    },
  },
  sidebarContainer: {},
  wellsContainerBid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  bidWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: '10px',
  },
  bidHeadingContainer: {
    backgroundColor: '#7890a6',
    padding: '13px',
    borderRadius: '8px 8px 0 0 ',
  },
  bottomWellContainer: {
    maxWidth: '33%',
    flexBasis: '33%',
  },
  bidSection: {
    borderLeft: 'solid 1px #000000',
    background: '#F9F9F9',
  },
  blueHeadingClone: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'bold',
  },
  wellAttrTitle: {
    lineHeight: '20px',
    marginBottom: '8px',
  },
  wellAttrValue: {
    lineHeight: '20px',
  },
  status: {
    marginTop: '8px',
  },
  bidContainer: {

    '@media screen and (max-width: 1280px)': {
      margin: '20px 0',
    },

    '@media screen and (max-width: 960px)': {
      margin: '12px 0',
    },

    '@media screen and (max-width: 600px)': {
      alignItems: 'center',
      padding: '32px 20px',
    },
  },
  bidHeading: {
    marginBottom: '16px',
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 'normal',
    color: '#ffffff',

    '@media screen and (max-width: 600px)': {
      fontSize: '22px',
    },
  },

  bidStatValue: {
    marginTop: '8px',
    fontWeight: 'bold',
  },
  valuesByWell: {
    margin: '10px 0px',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    boxShadow: 'unset',
    padding: '10px 30px',

    '&:hover': {
      backgroundColor: secondaryMain,
      color: white,
    },
  },
  bidButtonContainer: {
    display: 'flex',
  },
  amountContainer: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  wellAmountName: {
    fontSize: '18px',
    fontWeight: '500',
    height: 'calc(100% - 23px)',
    minWidth: '52px',
  },
  transactionNotificationText: {
    textAlign: 'center',
    fontSize: '18px',
  },
  infoIcon: {
    color: '#777777',
    height: 20,
    marginLeft: 5,
    width: 20,
    marginTop: 1,
  },
  infoIconCotainer: {
    display: 'flex',
  },
  downloadButton: {
    height: '52px',
    width: '220px',
    borderRadius: '8px',
    border: 'unset',
    outline: 'none',
    color: '#000',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '400',
    textAlign: 'center',
    textTransform: 'none',
    background: '#fff',
    boxShadow: 'unset',

    '&:hover': {
      backgroundColor: secondaryMain,
      color: white,
    },

    '@media screen and (max-width: 960px)': {
      marginTop: '20px',
    },
  },
};
