import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Field, formValues, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect, useCallback } from 'react';

// layouts
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import BottomSection from 'layouts/AuthSections/BottomSection';
import MainSection from 'now-frontend-shared/layouts/AuthSections/MainSection';
import HeadSection from 'now-frontend-shared/layouts/AuthSections/HeadSection';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';
import ProfileSection from 'layouts/ProfileSection';

// components
import AmountInput from 'now-frontend-shared/components/inputs/AmountInput';
import Button from 'now-frontend-shared/components/Button';
import RequiredDescription from 'now-frontend-shared/components/inputs/RequiredDescription';
import Spinner from 'now-frontend-shared/components/Spinner';
import StyledLink from 'pages/Profile/components/StyledLink';
import Text from 'pages/Profile/components/Text';

// validations
import { increaseBidAllowanceValidate } from 'utils/validation/validations';

// custom hooks
import useWindowWidth from 'now-frontend-shared/hooks/useWindowWidth';
import { warnAboutUnsavedForm } from 'now-frontend-shared/hoc/warnAboutUnsavedForm';
// helpers
import { formatNumberToCurrency, maskedAmountToNumber } from 'now-shared/helpers/currency-helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

// store
import {
  cancelMyBidAllowanceRequest,
  getMyBidAllowanceDetails,
  getMyBidAllowanceRequest,
  setMyBidAllowanceRequest,
} from 'store/actions/myBidAllowanceActions';

import { BUYER_DASHBOARD, FINANCING_INFORMATION_ROUTE } from 'constants/registrationFlow';

const formName = 'increaseBidAllowance';

const IncreaseBidAllowance = ({
  classes,
  handleSubmit,
  allowanceDetails,
  allowanceDetailsLoading,
  allowanceRequest,
  allowanceRequestLoading,
  bankInformation,
  companyIsApprovedAndActive,
  hasBuyerAgreement,
  isAccountManager,
  requestedBidAllowanceIncrease: requestedBidAllowanceIncreaseFormatted,
}) => {
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();

  const bankInformationApproved = bankInformation && bankInformation.status.title === 'approved';
  const isApplicable = hasBuyerAgreement && bankInformationApproved && companyIsApprovedAndActive;

  useEffect(() => {
    if (isApplicable) {
      if (isAccountManager) {
        dispatch(getMyBidAllowanceRequest());
      }
      dispatch(getMyBidAllowanceDetails());
    }
  }, [dispatch, isApplicable, isAccountManager]);

  const bidAllowance = allowanceDetails?.bidAllowance;
  const bidAllowanceAvailable = allowanceDetails?.bidAllowanceAvailable;
  const loadingMessage = 'loading...';
  const notApplicableMessage = 'n/a';
  const loadingOrNotApplicableMessage = isApplicable ? loadingMessage : notApplicableMessage;

  const bidAllowanceFormatted = useMemo(() => {
    if (bidAllowance !== undefined) {
      return formatNumberToCurrency(bidAllowance);
    }
    return loadingOrNotApplicableMessage;
  }, [bidAllowance, loadingOrNotApplicableMessage]);

  const bidAllowanceAvailableFormatted = useMemo(() => {
    if (bidAllowanceAvailable !== undefined) {
      return formatNumberToCurrency(bidAllowanceAvailable);
    }
    return loadingOrNotApplicableMessage;
  }, [bidAllowanceAvailable, loadingOrNotApplicableMessage]);

  const requestedBidAllowanceIncrease = useMemo(
    () => maskedAmountToNumber(requestedBidAllowanceIncreaseFormatted),
    [requestedBidAllowanceIncreaseFormatted],
  );

  const projectedBidAllowance = useMemo(() => {
    if (bidAllowance !== undefined && requestedBidAllowanceIncrease !== undefined) {
      return bidAllowance + requestedBidAllowanceIncrease;
    }
  }, [bidAllowance, requestedBidAllowanceIncrease]);

  const projectedBidAllowanceFormatted = useMemo(() => {
    if (projectedBidAllowance !== undefined) {
      return formatNumberToCurrency(projectedBidAllowance);
    }
    return '-';
  }, [projectedBidAllowance]);

  const projectedBidAllowanceAvailable = useMemo(() => {
    if (bidAllowanceAvailable !== undefined && requestedBidAllowanceIncrease !== undefined) {
      return bidAllowanceAvailable + requestedBidAllowanceIncrease;
    }
  }, [bidAllowanceAvailable, requestedBidAllowanceIncrease]);

  const projectedBidAllowanceAvailableFormatted = useMemo(() => {
    if (projectedBidAllowanceAvailable !== undefined) {
      return formatNumberToCurrency(projectedBidAllowanceAvailable);
    }
    return '-';
  }, [projectedBidAllowanceAvailable]);

  const formChanged = !allowanceRequest
    || allowanceRequest.requestedBidAllowanceIncrease !== requestedBidAllowanceIncrease;
  const canUpdate = (
    isAccountManager
    && allowanceRequest
    && ['pending', 'rejected', 'deleted'].includes(allowanceRequest.status.title)
  );
  const canCancel = (
    isAccountManager
    && allowanceRequest
    && ['pending', 'rejected'].includes(allowanceRequest.status.title)
  );
  const canSubmit = (
    isAccountManager
    && hasBuyerAgreement
    && bankInformationApproved
    && companyIsApprovedAndActive
    && (
      !allowanceRequest
      || (
        canUpdate
        && (
          formChanged
          || allowanceRequest.status.title === 'deleted'
        )
      )
    )
  );

  const onHandleSubmit = useCallback(({ requestedBidAllowanceIncrease: requestedBidAllowanceIncreaseFormatted }) => {
    const formData = {
      requestedBidAllowanceIncrease: maskedAmountToNumber(requestedBidAllowanceIncreaseFormatted),
    };
    dispatch(setMyBidAllowanceRequest(formData));
  }, [dispatch]);

  const onHandleCancelRequest = useCallback(() => {
    dispatch(cancelMyBidAllowanceRequest());
  }, [dispatch]);

  const isSmallScreen = useMemo(() => windowWidth < 1150, [windowWidth]);

  return (
    <FormLayout onSubmit={handleSubmit(onHandleSubmit)}>
      <HeadSection heading="Request to Increase Bid Allowance" path={BUYER_DASHBOARD} />

      <Grid container direction="column" className={classes.infoContainer}>
        {(allowanceDetailsLoading || allowanceRequestLoading) && <Spinner backdrop />}

        <Grid
          container
          item
          direction="column"
        >
          {!companyIsApprovedAndActive && (
            <ProfileSection heading="Company approval">
              <Text>Your company needs to be approved before you can request a bid allowance increase</Text>
            </ProfileSection>
          )}

          {!isAccountManager && (
            <ProfileSection heading="Account Manager">
              <Text>Only the Account Manager can request a bid allowance increase</Text>
            </ProfileSection>
          )}

          {!hasBuyerAgreement && (
            <ProfileSection heading="Buyer status" isSubmitted={false}>
              <Text>You must agree to the Buyer's Agreement before you can bid.</Text>
              <StyledLink path="/buyer-agreement">Complete the Buyer's Agreement</StyledLink>
            </ProfileSection>
          )}

          {!bankInformationApproved && (
            <ProfileSection heading="Bank information" status={bankInformation?.status.title}>
              <Text>You must submit your bank information and request a bid allowance before you can bid.</Text>
              <StyledLink path={FINANCING_INFORMATION_ROUTE}>Submit Bank Information</StyledLink>
            </ProfileSection>
          )}

          <ProfileSection heading="Total bid allowance">
            <Text>{bidAllowanceFormatted}</Text>
          </ProfileSection>

          <ProfileSection heading="Available allowance">
            <Text>{bidAllowanceAvailableFormatted}</Text>
          </ProfileSection>

          {allowanceRequest && (
            <ProfileSection
              heading="Current bid allowance request"
              status={
                allowanceRequest.status.title === 'deleted' ? 'canceled'
                  : allowanceRequest.status.title
              }
            >
              <Text>
                +
                {formatNumberToCurrency(allowanceRequest.requestedBidAllowanceIncrease)}
              </Text>
            </ProfileSection>
          )}
        </Grid>
      </Grid>

      <Grid container className={classes.infoContainer}>
        <span className={classes.heading}>Notifications</span>

        <span className={classes.description}>
          Please enter the desired amount of additional bid allowance and click submit.
          We will contact your bank
          or private equity provider to confirm your requested bid allowance within one business day.
          Please submit this information only once.
          Your Total Bid Allowance will not reflect a change until we have processed your request.
        </span>

        <span className={classes.description}>
          Please be sure to let your banker / private equity contact know we will be calling to confirm credit.
        </span>
      </Grid>

      <MainSection>
        <LabelLayout
          name="requestedBidAllowanceIncrease"
          label="Requested Bid Allowance Increase"
          isRequired
          disabled={!canUpdate}
          multiple={isSmallScreen}
        >
          <Field name="requestedBidAllowanceIncrease" component={AmountInput} />
        </LabelLayout>

        <LabelLayout
          name="projectedBidAllowance"
          label="Projected Total Bid Allowance"
        >
          {projectedBidAllowanceFormatted}
        </LabelLayout>

        <LabelLayout
          name="projectedBidAllowanceAvailable"
          label="Projected Available Bid Allowance"
        >
          {projectedBidAllowanceAvailableFormatted}
        </LabelLayout>

        <RequiredDescription />
      </MainSection>

      <BottomSection>
        <Grid container alignItems="center" className={classes.buttonContainer}>
          <Grid item className={classes.button}>
            <Button
              type="submit"
              label={allowanceRequest ? 'Resubmit' : 'Submit'}
              buttonColor="green"
              disabled={!canSubmit}
            />
          </Grid>

          {allowanceRequest && (
            <Grid item className={classes.button}>
              <Button
                label="Cancel Request"
                buttonColor="red"
                onClick={onHandleCancelRequest}
                disabled={!canCancel}
              />
            </Grid>
          )}
        </Grid>
      </BottomSection>
    </FormLayout>
  );
};

IncreaseBidAllowance.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func,
  allowanceDetails: PropTypes.object,
  bankInformation: PropTypes.object,
  isAccountManager: PropTypes.bool.isRequired,
  companyIsApprovedAndActive: PropTypes.bool.isRequired,
  // TODO: [TYPE] specify missing types
};

export default compose(
  connect(({ auth, myBidAllowance }) => ({
    initialValues: myBidAllowance.allowanceRequest ? {
      ...myBidAllowance.allowanceRequest,
      requestedBidAllowanceIncrease: String(myBidAllowance.allowanceRequest.requestedBidAllowanceIncrease),
    } : undefined,
    allowanceDetails: myBidAllowance.allowanceDetails,
    allowanceDetailsLoading: myBidAllowance.allowanceDetailsLoading,
    allowanceRequest: myBidAllowance.allowanceRequest,
    allowanceRequestLoading: myBidAllowance.allowanceRequestLoading,
    bankInformation: auth.user.company?.bankInformation,
    hasBuyerAgreement: auth.user.company?.hasBuyerAgreement,
    companyIsApprovedAndActive: !!auth.user.company?.approved && !!auth.user.company?.active,
    isAccountManager: auth.user.isAccountManager,
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    validate: increaseBidAllowanceValidate,
  }),
  warnAboutUnsavedForm,
  formValues('requestedBidAllowanceIncrease'),
  withStyles(styles),
)(IncreaseBidAllowance);
