import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import useSupercluster from 'use-supercluster';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import * as shapefile from '@mickeyjohn/shapefile';

import shape from 'assets/shape/shape.shp';

import { isUsingEsriMaps } from 'now-frontend-shared/features/feature-flags';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import EsriMap from './components/ESRI';

// custom hooks
import useIsMobile from 'now-frontend-shared/hooks/useIsMobile';

// styles and components from material-ui
import { Grid, Box } from '@material-ui/core';

// styles
import {
  getHistoricalListings,
  getPropertiesMarkers,
  getNewMexicoListings,
} from 'store/actions/mapActions';
import './styles.css';

// store
import InfoWindow from 'now-frontend-shared/components/Map/components/InfoWindow';
import {
  mapAreaOfInterestFillRgba,
  mapAreaOfInterestOutline,
  toRgbString,
} from 'now-frontend-shared/themes/colors';
import { MapDataGrid } from './components/MapDataGrid';

const Map = ({ pins, user: { paidEsriMaps } }) => {
  const [mapRef, setMapRef] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [infoWindowData, setInfoWindowData] = useState(null);
  const [mapScrollwheel, setMapScrollwheel] = useState(true);
  const [draggable, setDraggable] = useState(true);
  const [areasOfInterestPolygons, setAreaOfInterestPolygons]
    = useState(undefined);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const pinsCopy = useMemo(() => [...pins], [pins]);

  const areasOfInterestPolygonsCopy = useMemo(
    () => [...(areasOfInterestPolygons || [])],
    [areasOfInterestPolygons],
  );

  const getAreasOfInterestPolygons = useCallback(async () => {
    const source = await shapefile.openShp(shape);
    const polygons = [];
    let result = await source.read();
    while (!result.done) {
      const coords = [];
      polygons.push(coords);
      result.value.coordinates.forEach(coordinates => coordinates.forEach(coordinate => {
        if (Array.isArray(coordinate[0])) {
          coordinate.forEach(coord => coords.push({ lng: coord[0], lat: coord[1] }));
        } else {
          coords.push({ lng: coordinate[0], lat: coordinate[1] });
        }
      }));
      // eslint-disable-next-line no-await-in-loop
      result = await source.read();
    }
    return polygons;
  }, []);

  const loadAreasOfInterest = useCallback(() => {
    let result;
    if (isUsingEsriMaps()) {
      let canceled = false;
      const load = async () => {
        const polygons = await getAreasOfInterestPolygons();
        if (!canceled) {
          setAreaOfInterestPolygons(polygons);
        }
      };
      load();
      result = () => {
        canceled = true;
      };
    }
    return result;
  }, [getAreasOfInterestPolygons]);

  const handleBasemapLoaded = useCallback(() => {
    loadAreasOfInterest();
  }, [loadAreasOfInterest]);

  useEffect(() => {
    dispatch(getPropertiesMarkers());
    dispatch(getHistoricalListings());
    dispatch(getNewMexicoListings());
  }, [dispatch]);

  const points = pins.map(
    ({
      propertyId, projectName, wellCount, longitude, latitude,
    }) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        propertyId,
        projectName,
        wellCount,
      },
      geometry: {
        type: 'Point',
        coordinates: [longitude, latitude],
      },
    }),
  );

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 100 },
  });

  const getClusterProperties = useCallback(
    cluster => {
      const [longitude, latitude] = cluster.geometry.coordinates;

      if (cluster.id) {
        const clusterData = supercluster.getLeaves(cluster.id);
        const listings = clusterData.map(
          ({ properties: { propertyId, projectName, wellCount } }) => ({
            id: propertyId,
            projectName,
            wellCount,
          }),
        );
        setInfoWindowData({ id: cluster.id, listings });
      } else {
        setInfoWindowData({
          propertyId: cluster.properties.propertyId,
          listings: [
            {
              id: cluster.properties.propertyId,
              projectName: cluster.properties.projectName,
              wellCount: cluster.properties.wellCount,
            },
          ],
        });
      }

      mapRef.panTo({ lat: latitude, lng: longitude });
    },
    [supercluster, mapRef],
  );

  const handleToggleScrollwheel = useCallback(
    () => setMapScrollwheel(prevScrollwheel => !prevScrollwheel),
    [setMapScrollwheel],
  );

  const setMapDragging = useCallback(
    draggable => setDraggable(draggable),
    [setDraggable],
  );

  const handleCloseInfoWindow = useCallback(() => {
    setInfoWindowData(null);
    setMapScrollwheel(true);
    setMapDragging(true);
  }, [setInfoWindowData, setMapScrollwheel, setMapDragging]);

  const renderInfoWindow = ({ markerId, isCluster, pointCount }) => {
    if (isCluster && markerId === infoWindowData?.id) {
      return (
        <InfoWindow
          isCluster={isCluster}
          pointCount={pointCount}
          listings={infoWindowData.listings}
          handleToggleScrollwheel={handleToggleScrollwheel}
          handleCloseInfoWindow={handleCloseInfoWindow}
        />
      );
    }

    if (!isCluster && markerId === infoWindowData?.propertyId) {
      return (
        <InfoWindow
          isCluster={isCluster}
          pointCount={pointCount}
          listings={infoWindowData.listings}
          handleToggleScrollwheel={handleToggleScrollwheel}
          handleCloseInfoWindow={handleCloseInfoWindow}
        />
      );
    }
  };

  const handleApiLoaded = async (map, maps) => {
    const polygons = await getAreasOfInterestPolygons();
    polygons.forEach(coords => {
      const polygon = new maps.Polygon({
        paths: coords,
        strokeColor: toRgbString(mapAreaOfInterestOutline.strokeColorRgb),
        strokeOpacity: mapAreaOfInterestOutline.strokeOpacity,
        strokeWeight: mapAreaOfInterestOutline.strokeWeight,
        fillColor: toRgbString(mapAreaOfInterestFillRgba),
        fillOpacity: mapAreaOfInterestFillRgba[3],
      });
      polygon.setMap(map);
    });
  };

  const defaultZoomValue = useMemo(() => (isMobile ? 2 : 6), [isMobile]);

  const totalHeight = +window.innerHeight - 146;
  const [topBoxHeight, setTopBoxHeight] = useState(500);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);

  const handleMouseDown = useCallback(event => {
    setIsDragging(true);
    setStartY(event.clientY);
    event.preventDefault();
  }, []);

  const handleMouseMove = useCallback(
    event => {
      if (isDragging) {
        const newY = event.clientY;
        const diffY = newY - startY;
        const newHeight = Math.max(
          100,
          Math.min(topBoxHeight + diffY, totalHeight),
        );
        setTopBoxHeight(newHeight);
        setStartY(newY);
      }
    },
    [isDragging, startY, topBoxHeight, totalHeight],
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  React.useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  React.useEffect(() => {
    setTopBoxHeight(totalHeight / 2);
  }, [totalHeight]);

  const bottomBoxHeight = totalHeight - topBoxHeight;

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        maxHeight: '100vh',
        background: '#fff',
      }}
    >
      <Header />
      <div style={{ height: 'calc(100vh - 146px)', width: '100%' }}>
        <Box
          sx={{
            height: paidEsriMaps ? `${topBoxHeight}px` : '100%',
            width: '100%',
            position: 'relative',
            backgroundColor: 'lightblue',
          }}
        >
          {paidEsriMaps && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                height: '5px',
                cursor: 'ns-resize',
              }}
              onMouseDown={handleMouseDown}
            />
          )}
          <Grid container style={{ height: '100%' }}>
            {clusters && (
              <EsriMap
                areasOfInterestPolygons={areasOfInterestPolygonsCopy}
                listings={pinsCopy}
                onBasemapLoaded={handleBasemapLoaded}
              />
            )}
          </Grid>
        </Box>
        {paidEsriMaps && (
          <Box
            sx={{
              height: `${bottomBoxHeight}px`,
              width: '100%',
              backgroundColor: 'lightgray',
            }}
          >
            <div
              style={{
                height: '100%',
                width: '100%',
                background: '#fff',
                position: 'relative',
              }}
            >
              <MapDataGrid />
            </div>
          </Box>
        )}
      </div>
      <Footer />
    </div>
  );
};

Map.propTypes = {
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      projectName: PropTypes.string.isRequired,
      wellCount: PropTypes.number.isRequired,
      propertyId: PropTypes.number.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
  ),
};

Map.defaultProps = {
  pins: [],
};

export default compose(
  connect(({ map, user }) => ({
    pins: map.pins,
    user,
  })),
)(Map);
